import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {byteSize, openFile, setFileData, translate, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  updateEntity
} from './applicant-assessment.reducer';

export interface IApplicantAssessmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApplicantAssessmentUpdateState {
  isNew: boolean;
  assessmentId: string;
  assessmentEmailId: string;
}

export class ApplicantAssessmentUpdate extends React.Component<IApplicantAssessmentUpdateProps, IApplicantAssessmentUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      assessmentId: '0',
      assessmentEmailId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAssessments();
    this.props.getAssessmentEmails();
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { applicantAssessmentEntity } = this.props;
      const entity = {
        ...applicantAssessmentEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/applicant-assessment');
  };

  render() {
    const { applicantAssessmentEntity, assessments, assessmentEmails, loading, updating } = this.props;
    const { isNew } = this.state;

    const { examData, examDataContentType } = applicantAssessmentEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="evaluateyouApp.applicantAssessment.home.createOrEditLabel">
              <Translate contentKey="evaluateyouApp.applicantAssessment.home.createOrEditLabel">
                Create or edit a ApplicantAssessment
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : applicantAssessmentEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="applicant-assessment-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="applicant-assessment-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="scoreLabel" for="applicant-assessment-score">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.score">Score</Translate>
                  </Label>
                  <AvField
                    id="applicant-assessment-score"
                    type="string"
                    className="form-control"
                    name="score"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="maxScoreLabel" for="applicant-assessment-maxScore">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.maxScore">Max Score</Translate>
                  </Label>
                  <AvField
                    id="applicant-assessment-maxScore"
                    type="string"
                    className="form-control"
                    name="maxScore"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="examDataLabel" for="examData">
                      <Translate contentKey="evaluateyouApp.applicantAssessment.examData">Exam Data</Translate>
                    </Label>
                    <br />
                    {examData ? (
                      <div>
                        <a onClick={openFile(examDataContentType, examData)}>
                          <Translate contentKey="entity.action.open">Open</Translate>
                        </a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {examDataContentType}, {byteSize(examData)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('examData')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_examData" type="file" onChange={this.onBlobChange(false, 'examData')} />
                    <AvInput type="hidden" name="examData" value={examData} />
                  </AvGroup>
                </AvGroup>
                <AvGroup>
                  <Label id="fullNameLabel" for="applicant-assessment-fullName">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.fullName">Full Name</Translate>
                  </Label>
                  <AvField
                    id="applicant-assessment-fullName"
                    type="text"
                    name="fullName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="applicant-assessment-assessment">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.assessment">Assessment</Translate>
                  </Label>
                  <AvInput
                    id="applicant-assessment-assessment"
                    type="select"
                    className="form-control"
                    name="assessment.id"
                    value={isNew ? assessments[0] && assessments[0].id : applicantAssessmentEntity.assessment.id}
                    required
                  >
                    {assessments
                      ? assessments.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="applicant-assessment-assessmentEmail">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.assessmentEmail">Assessment Email</Translate>
                  </Label>
                  <AvInput id="applicant-assessment-assessmentEmail" type="select" className="form-control" name="assessmentEmail.id">
                    <option value="" key="0" />
                    {assessmentEmails
                      ? assessmentEmails.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/applicant-assessment" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  assessments: storeState.assessment.entities,
  assessmentEmails: storeState.assessmentEmail.entities,
  applicantAssessmentEntity: storeState.applicantAssessment.entity,
  loading: storeState.applicantAssessment.loading,
  updating: storeState.applicantAssessment.updating,
  updateSuccess: storeState.applicantAssessment.updateSuccess
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentUpdate);
