import React, {useEffect, useState} from 'react';
import {Card} from '@material-ui/core';
import {Col, Row, Table} from 'reactstrap';
import {IUserTraining} from 'app/shared/model/user-training.model';
import moment from 'moment/moment.js';
import {Translate} from 'react-jhipster';


export const TraineesTableDetails = (props:{userTrainings:IUserTraining[]}) =>{

  const {userTrainings} = props;
  const [IdArray, setIdArray] = useState([]);
  const [fnArray, setFnArray] = useState([]);
  const [emailArray, setEmailArray] = useState([]);
  const [trainingCount , setTrainingCount] = useState([]);

  const combinedArray = IdArray.map((id, i) => ({id, fullName: fnArray[i], email:  emailArray[i], count: trainingCount[i]}));


  useEffect(()=>{

    const today = new Date();
    const lastWeek = new Date(moment().subtract(7, "days").format("YYYY-MM-DD "));

    const uniqueTraineesIds = userTrainings.filter(ut => new Date(ut.createdDate) >= lastWeek).map(ut=>ut.user.id).filter((ut, i, a) => a.indexOf(ut) === i);
    uniqueTraineesIds.forEach(t=>{
      const trainingDates = userTrainings.filter(ut=>ut.user.id === t).filter(ut => new Date(ut.createdDate) >= lastWeek);
      const recentTrainingDates = trainingDates.filter(ut=>new Date(ut.createdDate) <= today && new Date(ut.createdDate) >= lastWeek);

      if(recentTrainingDates.length === trainingDates.length){
        const uniqueId = [...new Set(trainingDates.map(aa => aa.user.id))];
        const uniqueFn = [...new Set(trainingDates.map(aa => aa.user.fullName))];
        const uniqueEmail = [...new Set(trainingDates.map(aa => aa.user.email))];
        const counter = trainingDates.length;

        setTrainingCount(oldArray => [...oldArray, counter]);
        setIdArray(oldArray => [...oldArray, uniqueId]);
        setFnArray(oldArray => [...oldArray, uniqueFn]);
        setEmailArray(oldArray => [...oldArray, uniqueEmail]);
      }
    })
  },[userTrainings])


  return (
    <div>
      <Row>
        <Col md="9">
          <h4 className="pull-left" id="title-style">
            Recent Trainees
          </h4>
          <Card className="card-design" >

            <Row>
              <Col md="12">
                <div className="table-responsive">
                  {combinedArray && combinedArray.length > 0 ? (
                    <Table responsive aria-describedby="training-heading">
                      <thead>
                      <tr>
                        <th className="hand">Name</th>
                        <th className="hand">Email</th>
                         <th className="hand">Number of Trainings</th>
                      </tr>
                      </thead>
                      <tbody>
                      {combinedArray.map((training, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{training.fullName}</td>
                          <td>{training.email}</td>
                           <td>{training.count}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning">
                      <Translate contentKey="evaluateyouApp.training.home.notFound">No Trainees found</Translate>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

          </Card>
        </Col>

      </Row>
    </div>
  );

}
