import '../assessment.scss';
import React from 'react';
import {Button, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import {FormControl, InputLabel, Select} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAudit} from 'app/shared/model/audit.model';

interface CustomTechnologySelectionProps {
    audits: IAudit[];
    isCustom: boolean;
    selectedAuditCounters:AuditCounter[];

    onAuditChange:Function;
    previousStep:Function;
    nextStep: Function;

}

interface CustomTechnologySelectionState {
    errors:string[];

}

export class CustomTechnologySelection extends React.Component<CustomTechnologySelectionProps,CustomTechnologySelectionState> {

    maxQuestions: number;

    constructor(props) {
        super(props);
        this.maxQuestions = 30;
        this.state = {
            errors:[]
        }
    }

    render() {
        return (
            <div style={{height: '100%'}}>
                <div className="page-title">
                    <h4>
                        <span><b><Translate
                            contentKey="evaluateyouApp.assessment.steps.step3">Title</Translate></b></span>
                    </h4>

                    <p className="label-secondary">These are knowledge or tech skills that are important for the candidate. Choose a maximum of <span style={{fontWeight:'bold'}}>{this.maxQuestions}</span> questions.</p>
                </div>

                {this.state.errors ? (<label style={{color: "red"}}>{
                    this.state.errors.map(error=><div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}

                <AvGroup>
                    <Row style={{height: 'auto'}}>
                        {this.props.audits ? this.props.audits.map((audit, index) => (

                            <div key={index} className="technology-main">
                                <FormControl variant="filled" className="tech-question-count" error={this.getTotalQuestionCount() > this.maxQuestions? true:false}>
                                    <InputLabel htmlFor="filled-age-native-simple">Qty</InputLabel>
                                    <Select
                                        native
                                        name="questionCount"
                                        value={this.getQuestionCount(audit)}
                                        onChange={(event)=>this.updateCount(event,audit)}
                                    >
                                        <option aria-label="-" value="" />
                                        {[...Array(this.maxQuestions)].map((e, i) => <option key={i} value={i}>{i}</option>)}
                                    </Select>
                                </FormControl>
                                <div className="tech-description">
                                    <label><b>{audit.name}</b></label>
                                    <div style={{textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
                                        <span><i>{audit.description}</i></span>
                                    </div>
                                </div>
                            </div>

                        )) : <div>No secondary question categories found</div>}
                    </Row>
                    <AvFeedback>
                        <Translate contentKey="entity.validation.required">This field is
                            required.</Translate>
                    </AvFeedback>
                </AvGroup>
                <div className="marginTop marginBottom">
                    <Button onClick={() => this.props.previousStep()} color="info" className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="btn-primary">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>
                    <Button onClick={() => this.validate()} color="info"
                            className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
                        <FontAwesomeIcon icon="arrow-right"/>
                    </Button>
                </div>
            </div>
        )
    }

    validate():void{
        const totalQuestions = this.checkErrors();

        if(this.state.errors.length === 0 && totalQuestions > 0){

            // populate selected audits
            this.props.nextStep();
        }
    }

    private checkErrors() {
        this.setState({errors: []}); // clear existing errors
        const totalQuestions = this.getTotalQuestionCount();

        if (totalQuestions === 0) {
            this.setState({errors: ['Please select at least 1 question']});
        }

        if (totalQuestions > this.maxQuestions) {
            this.setState({errors: ['Total selected questions have exceeded ' + this.maxQuestions]});
        }
        return totalQuestions;
    }

    getTotalQuestionCount():number{
        const selectedQuestions = this.props.selectedAuditCounters;
        let totalQuestions = 0;
        selectedQuestions.forEach(existingAudit => {totalQuestions += existingAudit.count});

        return totalQuestions;
    }

    updateCount(event: React.ChangeEvent<{ name?: string; value: unknown }>, audit: IAudit) {
        const questionCount =  Number(event.target.value);
        audit.selectedQuestionCount = questionCount;

        let selectedQuestions = this.props.selectedAuditCounters;


        const selectedAuditCounter = selectedQuestions.find(existingAudit=>existingAudit.audit.name===audit.name);
        if(selectedAuditCounter){ // find existing audit and update amount
            selectedQuestions = selectedQuestions.map(selectedQuestion=> {selectedQuestion.audit.id === audit.id?selectedQuestion.count = questionCount:null; return selectedQuestion});
        }else{ // else push new audit amount
            selectedQuestions.push({
                audit: audit,
                count: questionCount
            });
        }

        const totalQuestions = this.checkErrors();
        if(totalQuestions <= this.maxQuestions){
            this.props.onAuditChange('allAudits',event,audit);
        }


    }

    getQuestionCount(audit: IAudit):number {
        const selectedQuestions = this.props.selectedAuditCounters;
        const selectedQuestion = selectedQuestions.find(existingAuditCounter=>existingAuditCounter.audit.id === audit.id);
        return selectedQuestion? selectedQuestion.count:0;
    }
}

export class AuditCounter{
    audit:IAudit;
    count:number
}
