import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, ITrainingSession} from 'app/shared/model/training-session.model';

export const ACTION_TYPES = {
  FETCH_TRAININGSESSION_LIST: 'trainingSession/FETCH_TRAININGSESSION_LIST',
  FETCH_TRAININGSESSION: 'trainingSession/FETCH_TRAININGSESSION',
  FETCH_TRAININGSESSION_DETAILS: 'trainingSession/FETCH_TRAININGSESSION_DETAILS',
  CREATE_TRAININGSESSION: 'trainingSession/CREATE_TRAININGSESSION',
  UPDATE_TRAININGSESSION: 'trainingSession/UPDATE_TRAININGSESSION',
  DELETE_TRAININGSESSION: 'trainingSession/DELETE_TRAININGSESSION',
  RESET: 'trainingSession/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITrainingSession>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  trainingSessionList: [] as ReadonlyArray<ITrainingSession>
};

export type TrainingSessionState = Readonly<typeof initialState>;

// Reducer

export default (state: TrainingSessionState = initialState, action): TrainingSessionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TRAININGSESSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TRAININGSESSION_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_TRAININGSESSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TRAININGSESSION):
    case REQUEST(ACTION_TYPES.UPDATE_TRAININGSESSION):
    case REQUEST(ACTION_TYPES.DELETE_TRAININGSESSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_TRAININGSESSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRAININGSESSION):
    case FAILURE(ACTION_TYPES.CREATE_TRAININGSESSION):
    case FAILURE(ACTION_TYPES.FETCH_TRAININGSESSION_DETAILS):
    case FAILURE(ACTION_TYPES.UPDATE_TRAININGSESSION):
    case FAILURE(ACTION_TYPES.DELETE_TRAININGSESSION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRAININGSESSION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRAININGSESSION_DETAILS):
      return {
        ...state,
        loading: false,
        trainingSessionList: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRAININGSESSION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TRAININGSESSION):
    case SUCCESS(ACTION_TYPES.UPDATE_TRAININGSESSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRAININGSESSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/training-sessions';

// Actions

export const getEntities: ICrudGetAllAction<ITrainingSession> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRAININGSESSION_LIST,
    payload: axios.get<ITrainingSession>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ITrainingSession> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRAININGSESSION,
    payload: axios.get<ITrainingSession>(requestUrl)
  };
};

export const getTrainingSessionDetails: ICrudGetAction<ITrainingSession> = id => {
  const requestUrl = `${apiUrl}/training/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRAININGSESSION_DETAILS,
    payload: axios.get<ITrainingSession>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ITrainingSession> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRAININGSESSION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITrainingSession> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRAININGSESSION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITrainingSession> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRAININGSESSION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
