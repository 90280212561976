import React from 'react';
import {Table} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {Button, CardContent} from "@material-ui/core";
import {
  diffSecondWithToday,
  getTimeBySecondInText,
  inPast,
  TIME_FORMAT,
  toTimeZoneWithFormat
} from 'app/shared/util/date-utils';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {IUser} from 'app/shared/model/user.model';
import {CONSTANTS} from 'app/shared/util/global.constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';

export const AssessmentTable = (props:{assessments: IApplicantAssessmentDTO[], account:IUser, title?:string}) =>{

    const history = useHistory();

    const getExpiredDate = (item:IApplicantAssessmentDTO) => {
        const isInPast = inPast(item.assessmentExpiryDate, null); // vm.timeZone
        const toString = toTimeZoneWithFormat(item.assessmentExpiryDate, props.account.timeZone, TIME_FORMAT.ddMMMyyyyZ);
        if (isInPast) {
            return (<span style={{color: "red"}}>{toString}</span>);
        }

        const second = diffSecondWithToday(item.assessmentExpiryDate, null, 'days');
        const time = getTimeBySecondInText(second);
        return (<span>{time}</span>);
    }

    const getScoreStatus = (item:IApplicantAssessmentDTO) => {
        if (item.score != null) {
            if (item.allowViewScore) {
                if ((item.score * 100 / item.maxScore) > item.assessmentPercent) {
                    return "Passed";
                } else {
                    return "Below Average";
                }
            } else {
                return "Withheld";
            }
        }
        return "";
    }

    const getStatus = (item) => {
        if (item.score != null) {
            return <FontAwesomeIcon size="2x" style={{color:'#3B86FF'}} icon={['far','check-circle']} title="Completed"/>;
        }

        const isInPast = inPast(item.assessmentExpiryDate, null);
        if (isInPast) {
            return <FontAwesomeIcon size="2x" style={{color:'#FF3BB7'}} icon={['far','times-circle']} title="Expired"/>;
        }

        const exam = localStorage.getItem(CONSTANTS.EXAM_DATA + item.assessmentId);
        if (exam == null) {
            return <FontAwesomeIcon size="2x" style={{color:'#65AD4B'}} icon={['far','circle']} title="Open"/>;
        }
        return <FontAwesomeIcon size="2x" style={{color:'#EBAB17'}} icon={['fas','circle-notch']} title="On-Going"/>;
    }

    const getStatusValue = (item) => {

        if (item.score != null || inPast(item.assessmentExpiryDate, null)) {
            return 0; // have done
        }
        const exam = localStorage.getItem(CONSTANTS.EXAM_DATA + item.assessmentId);
        if (exam != null) {
            return 1; // continue
        }
        return 2;// do-exam
    }

    const renderAssessmentAction = (assessment) => {

        const statusVal = getStatusValue(assessment);
        if(!assessment.createdDate && statusVal !== 2){
          return (
            <span>&nbsp;</span>
          )
        }
        if (statusVal === 1) {
            return (
                <Button onClick={()=>history.push(`/applicant-assessment/${assessment.assessmentId}/exam`)}>
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.continue">Continue Assessment</Translate>
                    </span>
                </Button>);
        } else if (statusVal === 2) {
            return (
                <Button onClick={()=>history.push(`/applicant-assessment/${assessment.assessmentId}/inform`)}>
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.doExam">Do Exam</Translate>
                    </span>
                </Button>);
        } else if (assessment.allowViewScore) {
            return (
                <Button onClick={()=>history.push(`/applicant-assessment/${assessment.assessmentId}/${assessment.createdBy}/d/exam-view`)}
                        color="primary">
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.view">View Results</Translate>
                    </span>
                </Button>);
        }
    }

    return (
        <div>
            <CardContent>
                {
                    props.title &&
                  <h4 className="pull-left mb-4" id="title-style">
                      {props.title}
                  </h4>
                }

                <Table responsive aria-describedby="audit-question-heading">
                    <thead>
                    <tr>
                        <th className="hand">
                            <Translate
                                contentKey="evaluateyouApp.applicantAssessment.dateAttempted">ID</Translate>
                        </th>
                        <th className="hand">
                            <Translate
                                contentKey="evaluateyouApp.applicantAssessment.title">Title</Translate>
                        </th>
                        <th className="hand">
                            <Translate
                                contentKey="evaluateyouApp.applicantAssessment.expiresOn">Expires On</Translate>
                        </th>
                        <th className="hand">
                            <Translate
                                contentKey="evaluateyouApp.applicantAssessment.score">Score</Translate>
                        </th>
                        <th className="hand">
                            <Translate
                                contentKey="evaluateyouApp.applicantAssessment.status">Status</Translate>
                        </th>

                        <th/>
                    </tr>
                    </thead>
                    <tbody style={{backgroundColor:'#ffffff'}}>
                    {props.assessments && props.assessments.map((assessment, i) => (
                        <tr key={`entity-${i}`}>
                            <td>
                                {
                                    assessment.createdDate?
                                        <TextFormat type="date" value={assessment.createdDate} format={APP_DATE_FORMAT}/>
                                        :
                                        <span>Not Attempted</span>
                                }
                            </td>
                            <td>{assessment.assessmentName}</td>
                            <td>{getExpiredDate(assessment)}</td>
                            <td>{getScoreStatus(assessment)}</td>
                            <td>{getStatus(assessment)}</td>
                            <td>{renderAssessmentAction(assessment)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </CardContent>
        </div>
    );

}
