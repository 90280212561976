import './candidate-dashboard.scss';
import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {
  AssessmentTable
} from 'app/modules/candidate-training-dashboard/components/assessment-table';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';
import {getProfileCompletion} from 'app/modules/account/settings/settings.reducer';
import {getMyTrainings} from 'app/entities/training/training.reducer';

export interface CandidateDashboardProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface CandidateDashboardState extends IPaginationBaseState {
  assessments: IApplicantAssessmentDTO[];
}


export class CandidateDashboard extends React.Component<CandidateDashboardProps, CandidateDashboardState> {
  applicantAssessmentService: ApplicantAssessmentService;

  constructor(props) {
    super(props);

    console.log(this.props.account);

    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      assessments: [],
    };

  }

  componentDidMount() {
    console.log('retrieving data!');

    this.applicantAssessmentService = new ApplicantAssessmentService();

    this.applicantAssessmentService.getApplicantAssessmentEmailDTOs().then(data => {
      if (data === null) {
        data = [];
      }
      this.setState({assessments: data});
    });
  }


  render() {
    return (
      <div>
        <Row>
          <Col sm={12}
               style={{alignItems: "center", paddingBottom: 60, textAlign: 'left'}}>
            <AssessmentTable account={this.props.account}
                             title={'Assessments'}
                             assessments={this.state.assessments}/>
          </Col>
        </Row>
      </div>
    );
  }


}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

const mapDispatchToProps = {
};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
