import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  TextFormat,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './assessment.reducer';
import {IAssessment} from 'app/shared/model/assessment.model';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import AssessmentService from 'app/shared/service/assessment.service';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {IApplicantAssessment} from "app/shared/model/applicant-assessment.model";

export interface IAssessmentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IAssessmentState extends IPaginationBaseState{
  assessments: IAssessment[];
  applicantAssessments: IApplicantAssessment[];
  totalApplicantAssessmentItems: number;
  totalAssessmentItems: number;
  view: number;
  isShowSearchForm: boolean;
  isSearching: boolean;
  showSuccessForm: boolean;
  activeAssessment: IAssessment;
}

export class AllAssessment extends React.Component<IAssessmentProps, IAssessmentState> {
  assessmentService: AssessmentService;
  applicantAssessmentService: ApplicantAssessmentService;
  options = [{value: 0, label: "By Assessment"}, {value: 1, label: "By Candidate"}];
  searchModel: any;
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      assessments: [],
      applicantAssessments: [],
      totalApplicantAssessmentItems: 0,
      totalAssessmentItems: 0,
      view: 0,
      order: 'asc',
      sort: 'title',
      isShowSearchForm: false,
      isSearching: false,
      showSuccessForm: false,
      activeAssessment: {}
    };
    this.applicantAssessmentService = new ApplicantAssessmentService();
    this.assessmentService = new AssessmentService();
    this.searchModel = {name: "", email : "", startTime: null, endTime: null};
  }

  componentDidMount() {
    this.getEntities(this.state.view, this.state.isSearching);
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities(this.state.view, this.state.isSearching);
    // this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = (view, isSearching) => {
    const { activePage, itemsPerPage, sort, order } = this.state;
   // this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=` + this.props.account.login);
    this.assessmentService.loadAll(activePage - 1, itemsPerPage, `${sort},${order}`).then(response => {
      const totalAssessmentItems = parseInt(response["headers"]['x-total-count'], 10);
      const assessments = response["data"];
      this.setState({totalAssessmentItems: totalAssessmentItems, assessments: assessments, view: view, isSearching: isSearching});
    });
  };

  showScore = (value) =>{
    return Math.round(value*100)/100;
  }

  setModelValue = (field, event) => {
    const iItem = {...this.searchModel};
    if(field === "allowViewScore" ||  field==="randomize"){
      iItem[field] = event.target.checked;
    }else{
      iItem[field] = event.target.value;
    }

    this.searchModel = iItem;
  }

  viewAssessment = (item) =>{
    this.props.history.push(`/assessment/${item.id}/al`);
  }
  updateShowAnswers = (assessment) =>{
    const {assessments} = this.state;
    const indexOf = assessments.indexOf(assessment);
      this.assessmentService.updateShowAnswers({id: assessment.id, status: !assessment.showAnswers}).then( response =>{
        assessment.showAnswers = response.showAnswers;
        if(indexOf >= 0){
          if(indexOf >= 0){
            assessments.splice(indexOf, 1);
            assessments.splice(indexOf, 0, assessment);
            this.setState({assessments: assessments, activeAssessment: assessment, showSuccessForm: true});
          }
        }
      });
  }
  hideDialog =() =>{
    this.setState({showSuccessForm: false, activeAssessment: {}});
  }
  renderSuccess(){
    const {showSuccessForm, activeAssessment} = this.state;
    return (<Modal isOpen={showSuccessForm} toggle={() => this.hideDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideDialog()}>Message</ModalHeader>
      <ModalBody>
        <Translate contentKey="evaluateyouApp.assessment.messages.updateShowAnswers" interpolate={{title:activeAssessment.title}}>Use Coupon</Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideDialog()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }
  renderAssessments(){
    const {assessments, totalAssessmentItems} = this.state;
    const {match} = this.props;
    return (<div>
      <div className="table-responsive">
        {this.renderSuccess()}
        {assessments && assessments.length > 0 ? (
          <Table responsive aria-describedby="assessment-heading">
            <thead>
            <tr>
              <th className="hand" onClick={this.sort('title')}>
                <Translate contentKey="evaluateyouApp.assessment.title">Title</Translate> <FontAwesomeIcon icon="sort"/>
              </th>
              <th className="hand" onClick={this.sort('expiry_date')}>
                <Translate contentKey="evaluateyouApp.assessment.expiryDate">Expiry Date</Translate> <FontAwesomeIcon
                icon="sort"/>
              </th>
              <th className="hand">
                <Translate contentKey="evaluateyouApp.assessment.totalApplicants"></Translate>
              </th>

              <th/>
            </tr>
            </thead>
            <tbody>
            {assessments && assessments.map((assessment, i) => (
              <tr key={`entity-${i}`}>
                <td>{assessment.title}</td>
                <td>
                  <TextFormat type="date" value={assessment.expiryDate} format={APP_DATE_FORMAT}/>
                </td>
                <td>{assessment.totalApplicants}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button onClick={() => this.viewAssessment(assessment)}  color="info" size="sm">
                      <FontAwesomeIcon icon="th-list"/>{' '}
                      <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                    </Button>
                    {assessment.showAnswers ? (
                        <Button onClick={() => this.updateShowAnswers(assessment)} color="info" size="sm">
                          <FontAwesomeIcon icon="eye-slash"/>{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.hideAnswers"></Translate>
                          </span>
                        </Button>) :
                      <Button onClick={() => this.updateShowAnswers(assessment)} color="info" size="sm">
                        <FontAwesomeIcon icon="eye"/>{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.showAnswers"></Translate>
                          </span>
                      </Button>
                    }
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="evaluateyouApp.assessment.home.notFound">No Assessments found</Translate>
          </div>
        )}
      </div>
      <div className={assessments && assessments.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={this.state.activePage} total={totalAssessmentItems} itemsPerPage={this.state.itemsPerPage}
                        i18nEnabled/>
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
            itemsPerPage={this.state.itemsPerPage}
            totalItems={totalAssessmentItems}
          />
        </Row>
      </div>
    </div>);
  }

  render() {
    const { match } = this.props;
    const { view, isShowSearchForm } = this.state;
    return (
      <div>
        <h2 id="assessment-heading" className="pull-left">
          <Translate contentKey="evaluateyouApp.assessment.home.title">Assessments</Translate>
        </h2>
        <Card className="main-card">
          {this.renderAssessments()}
        </Card>
      </div>
    );
  }

}

const mapStateToProps = ({ assessment, authentication }: IRootState) => ({
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllAssessment);
