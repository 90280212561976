import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CandidateAssessmentExperience from './candidate-assessment-experience';
import CandidateAssessmentExperienceDetail from './candidate-assessment-experience-detail';
import CandidateAssessmentExperienceUpdate from './candidate-assessment-experience-update';
import CandidateAssessmentExperienceDeleteDialog
  from './candidate-assessment-experience-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CandidateAssessmentExperienceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CandidateAssessmentExperienceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CandidateAssessmentExperienceDetail} />
      <ErrorBoundaryRoute path={match.url} component={CandidateAssessmentExperience} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CandidateAssessmentExperienceDeleteDialog} />
  </>
);

export default Routes;
