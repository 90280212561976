import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';

import {IRootState} from 'app/shared/reducers';
import {login} from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES, ENABLE_HIRER_DASHBOARD} from "app/config/constants";
import {Col, Row} from 'reactstrap';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const Login = (props: ILoginProps) => {
  const [showModal, setShowModal] = useState(props.showModal);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleLogin = (username, password, rememberMe = false) => props.login(username, password, rememberMe);

  const handleClose = () => {
    props.history.push('/');
  };

  const { location, isAuthenticated, isHirer, isAdmin } = props;
  const { from } = location.state || {
    from: {
      pathname: isHirer ? ( ENABLE_HIRER_DASHBOARD? '/assessor-dashboard': '/assessment')
        :
        (isAdmin ? "/audit" : "/dashboard"),
      search: location.search
    }
  };
  if (isAuthenticated) {
    return <Redirect to={from} />;
  }
  return(
    <Row className={'w-100 justify-content-center'}>
      <Col xs={12} md={6}>
        <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={props.loginError} />
      </Col>
    </Row>
  )
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  isAdmin: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]):false,
  isApplicant: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.APPLICANT]):false,
  isHirer: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.HIRER]):false,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
