import {ITraining} from 'app/shared/model/training.model';
import {ITrainingSchedule} from 'app/shared/model/training-schedule.model';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {ITrainingSession} from 'app/shared/model/training-session.model';
import {ITrainingDate} from 'app/shared/model/training-date.model';

/**
 * Returns true if there are enrollments associated with a Training
 * @param training
 */
export const hasEnrollments = (training: ITraining): boolean => {
  return training.id && training.userTrainings && training.userTrainings.length > 0;
};

export const countUserEnrollments = (enrollments: IUserTraining[], trainingDates: ITrainingDate[]): number => {
  if (!enrollments || !trainingDates) return 0;

  const enrolledInSchedule: IUserTraining[] = [];
  enrollments.forEach(enrollment => {
    if (trainingDates.filter(date => date.id === enrollment.trainingDate.id).length > 0) {
      enrolledInSchedule.push(enrollment);
    }
  });

  // count unique users in enrolledInSchedule
  const uniqueUsers = enrolledInSchedule.map(enrollment => enrollment.user.id).filter((id, index, array) => array.indexOf(id) === index);
  return uniqueUsers.length;
};

export const countEnrollmentsInSchedule = (trainingSchedule: ITrainingSchedule, enrollments: IUserTraining[]): number => {
  const trainingDates = trainingSchedule.sessions.flatMap(session => session.dates);
  return countUserEnrollments(enrollments, trainingDates);
};

export const countEnrollmentsInSession = (trainingSession: ITrainingSession, enrollments: IUserTraining[]): number => {
  const trainingDates = trainingSession.dates;
  return countUserEnrollments(enrollments, trainingDates);
};

export const countEnrollmentsInDates = (trainingDate: ITrainingDate, enrollments: IUserTraining[]): number => {
  const trainingDates = [trainingDate];
  return countUserEnrollments(enrollments, trainingDates);
};
