import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';

export const isPassed = (completedAssessments: IApplicantAssessmentDTO[]) => {
  const passedAssessments = completedAssessments.filter(
    completedAssessment => (completedAssessment.score / completedAssessment.maxScore) * 100 >= completedAssessment.assessmentPercent
  );
  if (passedAssessments.length > 0) {
    return true;
  } else {
    return false;
  }
};
