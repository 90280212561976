import './audit-detail.scss';

import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {IRootState} from 'app/shared/reducers';
import {getEntity} from './audit.reducer';
import {IAudit} from 'app/shared/model/audit.model';
import AuditQuestionService from "app/shared/service/audit-question.service";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {MARK_CONSTANTS} from "app/shared/util/global.constants";
import {IAuditQuestion} from "app/shared/model/audit-question.model";
import AuditService from "app/shared/service/audit.service";
import {displayHtml, displayImage, unescapeHtmlAndNewLine} from "app/shared/util/common-utils";

export interface IAuditDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface IAuditDetailState extends IPaginationBaseState {
  entity: IAudit;
  auditQuestionItems: IAuditQuestion[];
  totalItems: number;
  loadingEntity: boolean;
  loadingItems: boolean;
  showDeleteDialog: boolean;
  activeAuditQuestion: IAuditQuestion;
  enabledDropdownKey: string;
  enabledDropdownOpen: boolean;
  difficultyDropdownKey: string;
  difficultyDropdownOpen: boolean;
};

export class AuditDetail extends React.Component<IAuditDetailProps, IAuditDetailState> {
  auditQuestionService: AuditQuestionService;
  auditService: AuditService;

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      entity: {},
      auditQuestionItems: [],
      totalItems: 0,
      loadingEntity: true,
      loadingItems: true,
      showDeleteDialog: false,
      activeAuditQuestion: {},
      enabledDropdownKey: window.location.search.length > 20 ? urlParams.get("enabled") : "Enabled",
      enabledDropdownOpen: false,
      difficultyDropdownKey: window.location.search.length > 20 ? urlParams.get("difficulty") : "Hard",
      difficultyDropdownOpen: false,
      sort: 'createdDate',
      order: 'asc'
    };
  }

  componentDidMount() {
    this.auditService = new AuditService();
    this.auditQuestionService = new AuditQuestionService();

    this.auditService.getEntity(this.props.match.params.id).then(data => {
      this.setState({entity: data, loadingEntity: false});
      this.getEntities();
    });
    this.auditService.createTags(this.props.match.params.id).then(data => {
      console.log("success");
    })

  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&enabled=${this.state.enabledDropdownKey}&difficulty=${this.state.difficultyDropdownKey}`);
  }

  handlePagination = activePage => this.setState({activePage}, () => this.sortEntities());

  getEntities = () => {
    const {activePage, itemsPerPage, sort, order} = this.state;

    this.auditQuestionService.getEntitiesByAudit(activePage - 1, itemsPerPage, `${sort},${order}`, this.state.entity.id).then(body => {
      console.log(body);
      this.setState({})
      console.log(this.state.enabledDropdownKey + this.state.difficultyDropdownKey);
      if (this.state.enabledDropdownKey === "Enabled" && this.state.difficultyDropdownKey === "Hard")
        this.setState({
          totalItems: body['data'].filter(q => q.enabled === true).length,
          auditQuestionItems: body['data'].filter(q => q.enabled === true),
        });
      else if (this.state.enabledDropdownKey === "Disabled" && this.state.difficultyDropdownKey === "Hard")
        this.setState({
          totalItems: body['data'].filter(q => q.enabled === false).length,
          auditQuestionItems: body['data'].filter(q => q.enabled === false),
        });
      else if (this.state.enabledDropdownKey === "Enabled" && this.state.difficultyDropdownKey === "Medium")
        this.setState({
          totalItems: body['data'].filter(q => (q.enabled === true && q.score !== 3)).length,
          auditQuestionItems: body['data'].filter(q => q.enabled === true).filter(q => (q.enabled === true && q.score !== 3)),
        });
      else if (this.state.enabledDropdownKey === "Disabled" && this.state.difficultyDropdownKey === "Medium")
        this.setState({
          totalItems: body['data'].filter(q => (q.enabled === false && q.score !== 3)).length,
          auditQuestionItems: body['data'].filter(q => (q.enabled === false && q.score !== 3)),
        });
      else if (this.state.enabledDropdownKey === "Both" && this.state.difficultyDropdownKey === "Medium")
        this.setState({
          totalItems: body['data'].filter(q => (q.score !== 3)).length,
          auditQuestionItems: body['data'].filter(q => (q.score !== 3)),
        });
      else if (this.state.enabledDropdownKey === "Enabled" && this.state.difficultyDropdownKey === "Easy")
        this.setState({
          totalItems: body['data'].filter(q => (q.enabled === true && q.score === 1)).length,
          auditQuestionItems: body['data'].filter(q => (q.enabled === true && q.score === 1)),
        });
      else if (this.state.enabledDropdownKey === "Disabled" && this.state.difficultyDropdownKey === "Medium")
        this.setState({
          totalItems: body['data'].filter(q => (q.enabled === false && q.score === 1)).length,
          auditQuestionItems: body['data'].filter(q => (q.enabled === false && q.score === 1)),
        });
      else if (this.state.enabledDropdownKey === "Both" && this.state.difficultyDropdownKey === "Medium")
        this.setState({
          totalItems: body['data'].filter(q => q.score === 1).length,
          auditQuestionItems: body['data'].filter(q => q.score === 1),
        });
      else
        this.setState({
          totalItems: parseInt(body['headers']['x-total-count'], 10),
          auditQuestionItems: body['data'],
        });
      this.setState({
        loadingItems: false
      })
    });
  };

  getIndex = (index) => {
    return ITEMS_PER_PAGE * (this.state.activePage - 1) + index;
  }
  getPointText = (points) => {
    for (let i = 0; i < MARK_CONSTANTS.length; i++) {
      if (MARK_CONSTANTS[i].value === parseInt(points, 10)) {
        return MARK_CONSTANTS[i].label;
      }
    }
  }

  getDifficultyStyle = (points) => {
    for (let i = 0; i < MARK_CONSTANTS.length; i++) {
      if (MARK_CONSTANTS[i].value === parseInt(points, 10))
        if (MARK_CONSTANTS[i].label === "Easy")
          return "question-difficulty-easy";
        else if (MARK_CONSTANTS[i].label === "Medium")
          return "question-difficulty-medium";
        else if (MARK_CONSTANTS[i].label === "Hard")
          return "question-difficulty-hard";
    }
  }

  showPercentageCorrect = (value) => {
    return Math.round(value * 100) / 100;
  }


  editQuestion = (item) => {
    this.props.history.push("/audit-question/" + item.id + "/" + Math.random() + "/edit");
  }

  updateQuestionStatus = (item) => {
    const {auditQuestionItems} = this.state;
    const indexOf = auditQuestionItems.indexOf(item);
    this.auditQuestionService.updateEnabled({id: item.id, status: !item.enabled}).then(response => {
      if (response != null) {
        item.enabled = response.enabled;
        if (indexOf >= 0) {
          if (indexOf >= 0) {
            auditQuestionItems.splice(indexOf, 1);
            auditQuestionItems.splice(indexOf, 0, item);
            this.setState({auditQuestionItems: auditQuestionItems});
          }
        }
      }

    });
  }
  deleteQuestion = () => {
    const {auditQuestionItems, activeAuditQuestion} = this.state;
    const indexOf = auditQuestionItems.indexOf(activeAuditQuestion);
    this.auditQuestionService.deleteEntity(activeAuditQuestion.id).then(response => {
      if (indexOf >= 0) {
        auditQuestionItems.splice(indexOf, 1);
        this.setState({auditQuestionItems: auditQuestionItems, showDeleteDialog: false, activeAuditQuestion: {}});
      }
    });
  }

  confirmDeleteQuestion = (item) => {
    this.setState({showDeleteDialog: true, activeAuditQuestion: item});
  }

  handleCloseDeleteDialog = () => {
    this.setState({showDeleteDialog: false, activeAuditQuestion: {}});
  }

  renderDeleteDialog() {
    const {showDeleteDialog, activeAuditQuestion} = this.state;
    return (
      <Modal isOpen={showDeleteDialog} toggle={this.handleCloseDeleteDialog}>
        <ModalHeader toggle={this.handleCloseDeleteDialog}>
          <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        </ModalHeader>
        <ModalBody id="evaluateyouApp.auditQuestion.delete.question">
          <Translate contentKey="evaluateyouApp.auditQuestion.delete.question"
                     interpolate={{id: activeAuditQuestion.title}}>
            Are you sure you want to delete this AuditQuestion?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleCloseDeleteDialog}>
            <FontAwesomeIcon icon="ban"/>
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button id="jhi-confirm-delete-auditQuestion" color="danger" onClick={this.deleteQuestion}>
            <FontAwesomeIcon icon="trash"/>
            &nbsp;
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }


  toggleEnabledDropdown = () => {
    if (this.state.enabledDropdownOpen === false)
      this.setState({enabledDropdownOpen: true});
    else if (this.state.enabledDropdownOpen === true)
      this.setState({enabledDropdownOpen: false});
  }

  toggleDifficultyDropdown = () => {
    if (this.state.difficultyDropdownOpen === false)
      this.setState({difficultyDropdownOpen: true});
    else if (this.state.difficultyDropdownOpen === true)
      this.setState({difficultyDropdownOpen: false});
  }

  selectDifficultyFilter = (event) => {
    if (event === "Easy")
      this.setState({difficultyDropdownKey: "Easy"})
    else if (event === "Medium")
      this.setState({difficultyDropdownKey: "Medium"})
    else if (event === "Hard")
      this.setState({difficultyDropdownKey: "Hard"})
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&enabled=${this.state.enabledDropdownKey}&difficulty=${event}`);
  }

  selectEnabledFilter = (event) => {
    if (event === "Enabled")
      this.setState({enabledDropdownKey: "Enabled"})
    else if (event === "Disabled")
      this.setState({enabledDropdownKey: "Disabled"})
    else if (event === "Both")
      this.setState({enabledDropdownKey: "Both"})
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&enabled=${event}&difficulty=${this.state.difficultyDropdownKey}`);
  }

  renderAuditQuestions() {
    const {
      auditQuestionItems,
      totalItems,
      enabledDropdownKey,
      enabledDropdownOpen,
      difficultyDropdownOpen,
      difficultyDropdownKey
    } = this.state;
    const {match} = this.props;
    match.url = "/audit-question";
    return (
      <div>
        <Row>
          <h2 className={'mr-4'}>
            <Translate contentKey="evaluateyouApp.auditQuestion.home.title">Audit Questions</Translate>
          </h2>
          <Link to={`${match.url}/${this.state.entity.id}/new`} className="btn btn-primary jh-create-entity"
                id="jh-create-entity">
            <FontAwesomeIcon icon="plus"/>
            &nbsp;
            <Translate contentKey="evaluateyouApp.auditQuestion.home.createLabel">Create a new Audit
              Question</Translate>
          </Link>
        </Row>
        <div className={'mb-5'}>
          <Row>
            <h5>Filters</h5>
          </Row>
          <Row>
            <Col>
              <Dropdown isOpen={enabledDropdownOpen} toggle={this.toggleEnabledDropdown}
                        className="filter-dropdown-enabled">
                <DropdownToggle caret>
                  {enabledDropdownKey}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={event => this.selectEnabledFilter("Enabled")}>Enabled</DropdownItem>
                  <DropdownItem onClick={event => this.selectEnabledFilter("Disabled")}>Disabled</DropdownItem>
                  <DropdownItem onClick={event => this.selectEnabledFilter("Both")}>Both</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown isOpen={difficultyDropdownOpen} toggle={this.toggleDifficultyDropdown}
                        className="filter-dropdown-difficulty">
                <DropdownToggle caret>
                  {difficultyDropdownKey}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={event => this.selectDifficultyFilter("Easy")}>Easy</DropdownItem>
                  <DropdownItem onClick={event => this.selectDifficultyFilter("Medium")}>Medium</DropdownItem>
                  <DropdownItem onClick={event => this.selectDifficultyFilter("Hard")}>Hard</DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </Col>
          </Row>
        </div>
        <div className="table-responsive-audit">
          {auditQuestionItems && auditQuestionItems.length > 0 ? (
            <div className="justify-content-center">
              {auditQuestionItems.map((auditQuestion, i) => (
                <Card key={`q-${i}`} className="question-part">
                  <p>{this.getIndex(i + 1)}. {auditQuestion.enabled ? "" :
                    <span style={{textTransform: 'uppercase', color: '#8a6d3b'}}>[DISABLED]</span>}
                    <span className={this.getDifficultyStyle(auditQuestion.score)}>{this.getPointText(auditQuestion.score)}</span>
                    <span><Translate contentKey="evaluateyouApp.auditQuestion.duration"></Translate> : {auditQuestion.time}</span>
                  </p>
                  {(auditQuestion.totalCorrect !== 0 || auditQuestion.totalWrong !== 0) ? (
                      <Row>
                        <Col><Translate contentKey="evaluateyouApp.auditQuestion.totalCorrect">Total
                          Correct</Translate> : {auditQuestion.totalCorrect}</Col>
                        <Col><Translate contentKey="evaluateyouApp.auditQuestion.totalWrong">Total
                          Wrong</Translate> : {auditQuestion.totalWrong}</Col>
                        <Col>
                          <Translate contentKey="evaluateyouApp.auditQuestion.percentageCorrect">Percentage Correct</Translate> : {this.showPercentageCorrect(auditQuestion.totalCorrect * 100 / (auditQuestion.totalCorrect + auditQuestion.totalWrong))}
                        </Col>
                      </Row>
                  ) : ""}

                  {auditQuestion.tags ? (
                          <div className="chips">
                            {auditQuestion.tags.split(",").map((item, index) => (
                                <div className="chip" key={index}>{item}
                                </div>
                            ))}
                          </div>
                  ) : ""

                  }


                  <div className="mt-2">
                    <p className="multiline">
                      {auditQuestion.title}
                    </p>
                  </div>


                  {auditQuestion.code ? (
                    <SyntaxHighlighter style={docco} showLineNumbers={true}>
                      {auditQuestion.code}
                    </SyntaxHighlighter>
                  ) : (<span></span>)
                  }

                  {auditQuestion.questionFiles
                    ? auditQuestion.questionFiles.map((file, index) => (
                      <div key={file.id} style={{marginBottom: "5px"}}>
                        <img style={{padding: "10px"}} src={displayImage(file.data)}/>
                      </div>
                    ))
                    : null}

                  {auditQuestion.auditQuestionOptions
                    ? auditQuestion.auditQuestionOptions.map((auditQuestionOption, index) => (
                      <div key={auditQuestionOption.id} style={{marginTop: "15px", marginBottom: "15px"}}>
                        {auditQuestion.type === 3 ?
                          (<div>
                            <div className="pretty p-icon p-smooth">
                              <input type="checkbox" disabled={true} className="marginRight"/>
                              <div className="state p-primary">
                                <i className="icon mdi mdi-check"></i>
                                {!auditQuestionOption.isHighlight ? <label style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  display: "inline-flex",
                                  paddingLeft: "20px"
                                }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label> : ""}
                                <label>&nbsp;</label>
                              </div>
                            </div>
                            {auditQuestionOption.isHighlight ? (
                              <SyntaxHighlighter style={docco} showLineNumbers={true}>
                                {unescapeHtmlAndNewLine(auditQuestionOption.title)}
                              </SyntaxHighlighter>
                            ) : (<span></span>)
                            }
                            {auditQuestionOption.score === 1 ?
                              <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
                          </div>) : (<div>
                            <div className="pretty p-icon p-round p-smooth">
                              <input type="radio" disabled={true} className="marginRight"/>
                              <div className="state p-primary">
                                <i className="icon mdi mdi-check"></i>
                                {!auditQuestionOption.isHighlight ? <label style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  display: "inline-flex",
                                  paddingLeft: "20px"
                                }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label> : ""}
                                <label>&nbsp;</label>
                              </div>
                            </div>
                            {auditQuestionOption.isHighlight ? (
                              <SyntaxHighlighter style={docco} showLineNumbers={true}>
                                {unescapeHtmlAndNewLine(auditQuestionOption.title)}
                              </SyntaxHighlighter>
                            ) : (<span></span>)
                            }
                            {auditQuestionOption.score === 1 ?
                              <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
                          </div>)
                        }

                      </div>

                    ))
                    : null}
                  <div className="btn-group pull-left">
                    <Button color="info" onClick={() => this.editQuestion(auditQuestion)}>
                      <FontAwesomeIcon icon="pencil-alt"/>{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit"></Translate>
                        </span>
                    </Button>
                    {auditQuestion.enabled ?
                      <Button color="primary" onClick={() => this.updateQuestionStatus(auditQuestion)}>
                        <FontAwesomeIcon icon="eye-slash"/>{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.disable"></Translate>
                        </span>
                      </Button> : ""}
                    {!auditQuestion.enabled ?
                      <Button color="primary" onClick={() => this.updateQuestionStatus(auditQuestion)}>
                        <FontAwesomeIcon icon="eye"/>{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.enable"></Translate>
                        </span>
                      </Button> : ""}
                    <Button color="danger" onClick={() => this.confirmDeleteQuestion(auditQuestion)}>
                      <FontAwesomeIcon icon="trash"/>{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete"></Translate>
                        </span>
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="evaluateyouApp.auditQuestion.home.notFound">No Audit Questions found</Translate>
            </div>
          )}
        </div>


        <div className={auditQuestionItems && auditQuestionItems.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage}
                          i18nEnabled/>
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={this.state.activePage}
              onSelect={this.handlePagination}
              maxButtons={5}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const {entity, loadingEntity} = this.state;
    const {match} = this.props;

    return (
      <Row className="justify-content-center">
        <Col md="12">
          {loadingEntity ? (
            <p>Loading...</p>
          ) : (
            <Card>
              <h2>
                <b>{entity.name}</b>
              </h2>
              <dl className="jh-entity-details">
                <dt>
                  <span id="name">
                    <Translate contentKey="evaluateyouApp.audit.name">Name</Translate>
                  </span>
                </dt>
                <dd>{entity.name}</dd>
                <dt>
                  <span id="description">
                    <Translate contentKey="evaluateyouApp.audit.description">Description</Translate>
                  </span>
                </dt>
                <dd>{entity.description}</dd>
                <dt>
                  <span id="isCore">
                    <Translate contentKey="evaluateyouApp.audit.isCore">Is Core</Translate>
                  </span>
                </dt>
                <dd>{entity.isCore ? 'true' : 'false'}</dd>
              </dl>
              <Button tag={Link} to="/audit" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button tag={Link} to={`/audit/${entity.id}/edit`} replace color="primary">
                <FontAwesomeIcon icon="pencil-alt"/>{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
            </Card>
          )}

          {this.renderAuditQuestions()}
          {this.renderDeleteDialog()}

        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({audit}: IRootState) => ({
  entity: audit.entity
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditDetail);
