import './register.scss';

import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Button, Card, Col, Row} from 'reactstrap';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import {IRootState} from 'app/shared/reducers';
import {handleRegister, reset} from './register.reducer';
import {Link, RouteComponentProps} from 'react-router-dom';
import {getProfileRoles} from 'app/entities/profile-role/profile-role.reducer';
import {FULL_PROFILE_MODE} from 'app/config/constants';

export interface IRegisterProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
    location: any;
}

export interface IRegisterState {
    password: string;
    email: string;
    role: string;
}

export class RegisterPage extends React.Component<IRegisterProps,IRegisterState> {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            email: '',
            role: this.props.location.state?this.props.location.state.role:''
        };

        this.props.reset();
        this.props.getProfileRoles();
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.registrationSuccess && nextProps.loading === false) {
          this.props.history.push({pathname:'/account/register-success',state:{email:this.state.email}});
        }

        // if(nextProps.registrationFailure && nextProps.loading === false){
        //     toast.error(nextProps.errorMessage);
        // }
    }

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log(values.email);
    this.setState({email: values.email});
    this.props.handleRegister(values.email, values.email, values.firstPassword, this.state.role, this.props.currentLocale, values.currentRole, values.targetRole, values.agreeToMarketing, values.phoneNumber);

  };

  updatePassword = event => this.setState({password:event.target.value});

  render() {
     return(
       <div className="mx-4">
         <Card className="col-xs-10 col-lg-6" style={{marginTop: 80}}>
             <div>
                 <Row className="justify-content-center">
                     <Col md="6">
                         <h1 id="register-title" style={{textAlign: 'center'}}>
                           {
                             this.state && this.state.role === 'ROLE_HIRER' ? 'Employer ' : 'Candidate '
                           }
                         </h1>
                     </Col>
                 </Row>
                 <Row className="justify-content-center mt-2">
                     <Col md="6" className="align-items-center">
                         <h5 id="register-title" style={{textAlign: 'center'}}>
                             Create an account
                         </h5>
                         <h6 style={{textAlign: 'center'}}>
                             <Link to="/login">
                                 or login
                             </Link>
                         </h6>
                     </Col>
                 </Row>
                 <Row className="justify-content-center my-3">
                     <Col md="8">
                         <AvForm id="register-form" onValidSubmit={this.handleValidSubmit}>

                             <AvField
                                 name="email"
                                 label={translate('global.form.email.label')}
                                 placeholder={translate('global.form.email.placeholder')}
                                 type="email"
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: translate('global.messages.validate.email.required')
                                     },
                                     minLength: {
                                         value: 5,
                                         errorMessage: translate('global.messages.validate.email.minlength')
                                     },
                                     maxLength: {
                                         value: 254,
                                         errorMessage: translate('global.messages.validate.email.maxlength')
                                     }
                                 }}
                             />
                             <AvField
                                 name="firstPassword"
                                 label={translate('global.form.newpassword.label')}
                                 placeholder={translate('global.form.newpassword.placeholder')}
                                 type="password"
                                 onChange={this.updatePassword}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: translate('global.messages.validate.newpassword.required')
                                     },
                                     minLength: {
                                         value: 4,
                                         errorMessage: translate('global.messages.validate.newpassword.minlength')
                                     },
                                     maxLength: {
                                         value: 50,
                                         errorMessage: translate('global.messages.validate.newpassword.maxlength')
                                     }
                                 }}
                             />
                             <PasswordStrengthBar password={this.state.password}/>
                             <AvField
                                 name="secondPassword"
                                 label={translate('global.form.confirmpassword.label')}
                                 placeholder={translate('global.form.confirmpassword.placeholder')}
                                 type="password"
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: translate('global.messages.validate.confirmpassword.required')
                                     },
                                     minLength: {
                                         value: 4,
                                         errorMessage: translate('global.messages.validate.confirmpassword.minlength')
                                     },
                                     maxLength: {
                                         value: 50,
                                         errorMessage: translate('global.messages.validate.confirmpassword.maxlength')
                                     },
                                     match: {
                                         value: 'firstPassword',
                                         errorMessage: translate('global.messages.error.dontmatch')
                                     }
                                 }}
                             />
                             {
                                 this.state.role === 'ROLE_APPLICANT' && FULL_PROFILE_MODE &&
                                 <div>
                                     {/* current role */}
                                   <AvField
                                     className="form-control"
                                     name="currentRole"
                                     required
                                     type="select"
                                     label={'Your current role'}
                                     id="currentRole"
                                     placeholder={'your current role'}
                                   >
                                     <option key={''} value={''}>- Please Select -</option>
                                       {this.props.profileRoles.map((profileRole, i) => (
                                           <option key={i} value={profileRole.id}>{profileRole.name}</option>
                                       ))
                                       }
                                   </AvField>

                                     {/* looking for */}
                                   <AvField
                                     name="targetRole"
                                     type="select"
                                     required
                                     label={"Dream Job"}
                                     placeholder={'where do you see yourself in 3 years from now'}>
                                     <option key={''} value={''}>- Please Select -</option>
                                       {this.props.profileRoles.map((profileRole, i) => (
                                           <option key={i}
                                                   value={profileRole.id}>{profileRole.name}</option>
                                       ))
                                       }
                                   </AvField>

                                   <AvField
                                     name="phoneNumber"
                                     type="input"
                                     required
                                     label={"Mobile No"}
                                     placeholder={'e.g 0123116644'}>
                                   </AvField>

                                 </div>
                             }


                             <AvField
                                 name="agreeToPDPA"
                                 type="checkbox"
                                 required
                                 label="I agree to the Evaluateyou terms & conditions"/>

                             <AvField
                                 name="agreeToMarketing"
                                 type="checkbox"
                                 label="Send me newsletters and updates (Optional)"/>



                             <Button id="register-submit" color="primary" type="submit">
                                 <Translate contentKey="register.form.button">Register</Translate>
                             </Button>
                         </AvForm>

                     </Col>
                 </Row>
             </div>
         </Card>
       </div>
     );

  }
}

const mapStateToProps = ({ locale,profileRole,register }: IRootState) => ({
  currentLocale: locale.currentLocale,
  profileRoles: profileRole.entities,
  loading: register.loading,
registrationSuccess: register.registrationSuccess,
registrationFailure: register.registrationFailure,
    errorMessage: register.errorMessage
});

const mapDispatchToProps = {
    handleRegister,
    reset,
    getProfileRoles
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
