import {IAudit} from 'app/shared/model/audit.model';
import {IQuestionFile} from 'app/shared/model/question-file.model';
import {IAuditQuestionOption} from 'app/shared/model/audit-question-option.model';

export interface IAuditQuestion {
  id?: string;
  title?: string;
  code?: string;
  type?: number;
  score?: number;
  totalWrong?: number;
  totalCorrect?: number;
  order?: number;
  enabled?: boolean;
  comment?: string;
  candidateAnswers?: string[];
  tags?: string;
  time?: string;
  minutes?: number;
  seconds?: number;
  auditId?: string;
  audit?: IAudit;
  questionFiles?: IQuestionFile[];
  auditQuestionOptions?: IAuditQuestionOption[];
  renderType?: string;
}

export const defaultValue: Readonly<IAuditQuestion> = {
  title: '',
  code: '',
  type: 3,
  score: 1,
  totalWrong: 0,
  totalCorrect: 0,
  order: 1,
  enabled: true,
  comment: '',
  candidateAnswers: [],
  tags: '',
  time: '',
  minutes: 2,
  seconds: 0,
  questionFiles: [],
  auditQuestionOptions: []
};
