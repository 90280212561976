import {displayImage} from 'app/shared/util/common-utils';
import React from 'react';
import '../candidate-training-dashboard.scss';
import {Col, Row} from 'reactstrap';
import Button from '@material-ui/core/Button';
import {Card, CardActions, CardContent} from "@material-ui/core"
import {ITraining} from 'app/shared/model/training.model';
import {renderMarkDown} from 'app/shared/util/ui-utils';
import {Link} from "react-router-dom";


export const TrainingCardDisplay = (props:{trainingApplyList:readonly ITraining[]}) => {

    const {trainingApplyList} = props;

    return(
        <Row className="w-100">
            {trainingApplyList.map((training, i) => (
                <Col sm={4} key={`entity-${i}`}>

                    <Card style={{marginBottom:50, backgroundColor:"#ffffff", height:"auto"}}>
                        <CardContent className="px-0 py-0">
                            <Row className="w-100">
                                <img className="px-0 py-0" style={{padding: "10px", width:420, height:280, outline:'none'}} src={training.image?displayImage(training.image):'content/images/training-table.jpg'}/>
                            </Row>

                            <Row className="px-2 py-2 pull-left w-100">
                                <h4>{training.name}</h4>
                            </Row>
                            <Row className="px-2 py-2 pull-left w-100">
                                <div style={{textAlign:'left', height:20}} className="card-desc">
                                    <h6 dangerouslySetInnerHTML={{ __html: renderMarkDown(training.description)}} />
                                </div>
                            </Row>
                        </CardContent>

                        <CardActions>
                            <Row style={{marginLeft:0,width:"100%"}}>
                                <Button
                                    component={Link} to={`/training/${training.id}/detail`}
                                    variant="contained"
                                    className="mt-3 w-100 vertical-center"
                                    color="primary"
                                >
                                    View
                                </Button>
                            </Row>

                        </CardActions>

                    </Card>

                </Col>
            ))}
        </Row>
    )
}
