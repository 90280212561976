import {faSort} from '@fortawesome/free-solid-svg-icons/faSort';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faHdd} from '@fortawesome/free-solid-svg-icons/faHdd';
import {faTachometerAlt} from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import {faHeart} from '@fortawesome/free-solid-svg-icons/faHeart';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {faListAlt} from '@fortawesome/free-solid-svg-icons/faListAlt';
import {faTasks} from '@fortawesome/free-solid-svg-icons/faTasks';
import {faBook} from '@fortawesome/free-solid-svg-icons/faBook';
import {faLock} from '@fortawesome/free-solid-svg-icons/faLock';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import {faThList} from '@fortawesome/free-solid-svg-icons/faThList';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons/faUserPlus';
import {faWrench} from '@fortawesome/free-solid-svg-icons/faWrench';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons/faAsterisk';
import {faFlag} from '@fortawesome/free-solid-svg-icons/faFlag';
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faRoad} from '@fortawesome/free-solid-svg-icons/faRoad';
import {faCloud} from '@fortawesome/free-solid-svg-icons/faCloud';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faPiggyBank} from '@fortawesome/free-solid-svg-icons/faPiggyBank';
import {faBookOpen} from '@fortawesome/free-solid-svg-icons/faBookOpen';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faBarcode} from '@fortawesome/free-solid-svg-icons/faBarcode';
import {faFire} from '@fortawesome/free-solid-svg-icons/faFire';
import {faUniversalAccess} from '@fortawesome/free-solid-svg-icons/faUniversalAccess';
import {faHandPointRight} from '@fortawesome/free-solid-svg-icons/faHandPointRight';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {faUpload} from '@fortawesome/free-solid-svg-icons/faUpload';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons/faWindowClose';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import {faCircle} from '@fortawesome/free-regular-svg-icons/faCircle';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload';
import {faCross} from '@fortawesome/free-solid-svg-icons/faCross';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';

import {library} from '@fortawesome/fontawesome-svg-core';
import {faCloudUploadAlt, faPhone, faTag} from '@fortawesome/free-solid-svg-icons';

export const loadIcons = () => {
  library.add(
    faSort,
    faEye,
    faEyeSlash,
    faSync,
    faBan,
    faTrash,
    faArrowLeft,
    faArrowRight,
    faSave,
    faPlus,
    faPencilAlt,
    faUser,
    faTachometerAlt,
    faHeart,
    faList,
    faListAlt,
    faTasks,
    faBook,
    faHdd,
    faLock,
    faSignInAlt,
    faSignOutAlt,
    faWrench,
    faThList,
    faUserPlus,
    faAsterisk,
    faFlag,
    faBell,
    faHome,
    faRoad,
    faCloud,
    faSearch,
    faEnvelope,
    faPiggyBank,
    faBookOpen,
    faCheckCircle,
    faCheck,
    faBarcode,
    faFire,
    faUniversalAccess,
    faHandPointRight,
    faChevronLeft,
    faChevronRight,
    faUpload,
    faWindowClose,
    faCheckSquare,
    faTimesCircle,
    faCircle,
    faCircleNotch,
    faDownload,
    faTag,
    faCloudUploadAlt,
    faCross,
    faMinus,
    faPhone
  );
};
