import {ITrainingDate} from 'app/shared/model/training-date.model';
import {ITrainingSchedule} from 'app/shared/model/training-schedule.model';

export interface ITrainingSession {
  id?: number;
  sessionName?: string;
  dates?: ITrainingDate[];

  trainingSchedule?: ITrainingSchedule;
}

export const defaultValue: Readonly<ITrainingSession> = {};
