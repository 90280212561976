import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {Alert, Button, Card, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Link} from 'react-router-dom';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}

class LoginModal extends React.Component<ILoginModalProps> {
  handleSubmit = (event, errors, { username, password, rememberMe }) => {
    const { handleLogin } = this.props;
    handleLogin(username, password, rememberMe);
  };

  render() {
    const { loginError, handleClose } = this.props;

    return (
      <Card>
        <AvForm onSubmit={this.handleSubmit}>
          <Row className="justify-content-center">
            <Col md="6">
              <h1 id="login-title" style={{ textAlign: 'center' }}>
                <Translate contentKey="login.title">Sign in</Translate>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <div style={{ textAlign: 'center',fontSize:'smaller' }}>
                Need an Account? &nbsp;&nbsp;&nbsp;
                <Link to="/choose-role">
                  Create an account
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              {loginError ? (
                <Alert color="danger">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <AvField
                name="username"
                label={translate('global.form.email.label')}
                placeholder={translate('global.form.email.placeholder')}
                required
                errorMessage="Email cannot be empty!"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <AvField
                name="password"
                type="password"
                label={translate('login.form.password')}
                placeholder={translate('login.form.password.placeholder')}
                required
                errorMessage="Password cannot be empty!"
              />
            </Col>
          </Row>

          <Row className={'justify-content-center'}>
            <Col md={8}>
              <Row>
                <Col xs={12} md={6} className={'my-2'}>
                  <AvGroup check inline>
                    <Label className="form-check-label">
                      <AvInput type="checkbox" name="rememberMe" /> <span style={{fontWeight:400}}>Remember me</span>
                    </Label>
                  </AvGroup>
                </Col>
                <Col xs={12} md={6} className={'my-2'}>
                  <Link to="/account/reset/request" style={{ fontSize: 'smaller' }}>
                    <Translate contentKey="login.password.forgot">Forgot?</Translate>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center my-4">
            <Col md={8}>
              <Row>
                <Col md="6" style={{ textAlign: 'center' }} className={'my-2'}>
                  <Button color="primary" type="submit">
                    <Translate contentKey="login.form.button">Sign in</Translate>
                  </Button>
                </Col>
                <Col md="6" style={{ textAlign: 'center' }} className={'my-2'}>
                  <Button color="secondary" type="button" onClick={handleClose} tabIndex="1">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                  </Button>
                </Col>
              </Row>
            </Col>

          </Row>
        </AvForm>
      </Card>
    );
  }
}

export default LoginModal;
