import {combineReducers} from 'redux';
import {loadingBarReducer as loadingBar} from 'react-redux-loading-bar';

import locale, {LocaleState} from './locale';
import authentication, {AuthenticationState} from './authentication';
import applicationProfile, {ApplicationProfileState} from './application-profile';

import administration, {
  AdministrationState
} from 'app/modules/administration/administration.reducer';
import userManagement, {
  UserManagementState
} from 'app/modules/administration/user-management/user-management.reducer';
import register, {RegisterState} from 'app/modules/account/register/register.reducer';
import activate, {ActivateState} from 'app/modules/account/activate/activate.reducer';
import password, {PasswordState} from 'app/modules/account/password/password.reducer';
import settings, {SettingsState} from 'app/modules/account/settings/settings.reducer';
import passwordReset, {
  PasswordResetState
} from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import audit, {AuditState} from 'app/entities/audit/audit.reducer';
// prettier-ignore
import auditQuestion, {
  AuditQuestionState
} from 'app/entities/audit-question/audit-question.reducer';
// prettier-ignore
import auditQuestionOption, {
  AuditQuestionOptionState
} from 'app/entities/audit-question-option/audit-question-option.reducer';
// prettier-ignore
import auditTag, {AuditTagState} from 'app/entities/audit-tag/audit-tag.reducer';
// prettier-ignore
import questionFile, {QuestionFileState} from 'app/entities/question-file/question-file.reducer';
// prettier-ignore
import coupon, {CouponState} from 'app/entities/coupon/coupon.reducer';
// prettier-ignore
import credit, {CreditState} from 'app/entities/credit/credit.reducer';
// prettier-ignore
import creditHistory, {
  CreditHistoryState
} from 'app/entities/credit-history/credit-history.reducer';
// prettier-ignore
import paymentRequest, {
  PaymentRequestState
} from 'app/entities/payment-request/payment-request.reducer';
// prettier-ignore
import myAuditQuestion, {
  MyAuditQuestionState
} from 'app/entities/my-audit-question/my-audit-question.reducer';
// prettier-ignore
import myAuditQuestionOption, {
  MyAuditQuestionOptionState
} from 'app/entities/my-audit-question-option/my-audit-question-option.reducer';
// prettier-ignore
import assessment, {AssessmentState} from 'app/entities/assessment/assessment.reducer';
// prettier-ignore
import assessmentAudit, {
  AssessmentAuditState
} from 'app/entities/assessment-audit/assessment-audit.reducer';
// prettier-ignore
import assessmentEmail, {
  AssessmentEmailState
} from 'app/entities/assessment-email/assessment-email.reducer';
// prettier-ignore
import applicantAssessment, {
  ApplicantAssessmentState
} from 'app/entities/applicant-assessment/applicant-assessment.reducer';
// prettier-ignore
import candidateAssessmentExperience, {
  CandidateAssessmentExperienceState
} from 'app/entities/candidate-assessment-experience/candidate-assessment-experience.reducer';
// prettier-ignore
import adminConfig, {AdminConfigState} from 'app/entities/admin-config/admin-config.reducer';
// prettier-ignore
import education, {EducationState} from 'app/entities/education/education.reducer';
// prettier-ignore
import workExperience, {
  WorkExperienceState
} from 'app/entities/work-experience/work-experience.reducer';
// prettier-ignore
import training, {TrainingState} from 'app/entities/training/training.reducer';
// prettier-ignore
import trainingSchedule, {
  TrainingScheduleState
} from 'app/entities/training-schedule/training-schedule.reducer';
// prettier-ignore
import trainingSession, {
  TrainingSessionState
} from 'app/entities/training-session/training-session.reducer';
// prettier-ignore
import trainingDate, {TrainingDateState} from 'app/entities/training-date/training-date.reducer';
// prettier-ignore
import tag, {TagState} from 'app/entities/tag/tag.reducer';

// prettier-ignore
import profileRole, {ProfileRoleState} from 'app/entities/profile-role/profile-role.reducer';
// prettier-ignore
import faculty, {FacultyState} from 'app/entities/faculty/faculty.reducer';
// prettier-ignore
import course, {CourseState} from 'app/entities/course/course.reducer';
// prettier-ignore
import major, {MajorState} from 'app/entities/major/major.reducer';
// prettier-ignore
import university, {UniversityState} from 'app/entities/university/university.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly audit: AuditState;
  readonly auditQuestion: AuditQuestionState;
  readonly auditQuestionOption: AuditQuestionOptionState;
  readonly auditTag: AuditTagState;
  readonly questionFile: QuestionFileState;
  readonly coupon: CouponState;
  readonly credit: CreditState;
  readonly creditHistory: CreditHistoryState;
  readonly paymentRequest: PaymentRequestState;
  readonly myAuditQuestion: MyAuditQuestionState;
  readonly myAuditQuestionOption: MyAuditQuestionOptionState;
  readonly assessment: AssessmentState;
  readonly assessmentAudit: AssessmentAuditState;
  readonly assessmentEmail: AssessmentEmailState;
  readonly applicantAssessment: ApplicantAssessmentState;
  readonly candidateAssessmentExperience: CandidateAssessmentExperienceState;
  readonly adminConfig: AdminConfigState;
  readonly education: EducationState;
  readonly workExperience: WorkExperienceState;
  readonly training: TrainingState;
  readonly trainingSchedule: TrainingScheduleState;
  readonly trainingSession: TrainingSessionState;
  readonly trainingDate: TrainingDateState;
  readonly tag: TagState;
  readonly profileRole: ProfileRoleState;
  readonly faculty: FacultyState;
  readonly course: CourseState;
  readonly major: MajorState;
  readonly university: UniversityState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  audit,
  auditQuestion,
  auditQuestionOption,
  auditTag,
  questionFile,
  coupon,
  credit,
  creditHistory,
  paymentRequest,
  myAuditQuestion,
  myAuditQuestionOption,
  assessment,
  assessmentAudit,
  assessmentEmail,
  applicantAssessment,
  candidateAssessmentExperience,
  adminConfig,
  education,
  workExperience,
  training,
  trainingSchedule,
  trainingSession,
  trainingDate,
  tag,
  profileRole,
  faculty,
  course,
  major,
  university,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
