import React from 'react';
import {ITrainingDetailProps, ITrainingDetailState} from 'app/entities/training/training-detail';
import {IRootState} from 'app/shared/reducers';
import {Card, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';
import {getEntity} from 'app/entities/training/training.reducer';
import {getParameterByName} from 'app/shared/util/entity-utils';

export class WatchTraining extends React.Component<ITrainingDetailProps,ITrainingDetailState> {

    constructor(props) {
        super(props);
        this.state = {
            duration: 0,
            training: {}
        };

        this.props.getEntity(this.props.match.params.id);
    }

    render(){
        return(
            <div className="px-5 mb-5">
                <Card>
                    <CardContent>
                        {
                            this.props.trainingEntity &&
                          <iframe width="80%" height="800" src={`https://www.youtube.com/embed/${getParameterByName('v', this.props.trainingEntity.youTubeLink)}`}
                                  title="YouTube video player" frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen></iframe>
                        }

                    </CardContent>
                </Card>
            </div>
        );
    }

}


const mapStateToProps = ({
                             training,
                         }: IRootState) => ({
    trainingEntity: training.entity,
});

const mapDispatchToProps = {
    getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WatchTraining);
