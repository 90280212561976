import './landing.scss';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Button, Card, Col, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {AUTHORITIES} from "app/config/constants";

export interface IChooseRoleProps extends StateProps, RouteComponentProps<{ id: string }> {}
export interface IChooseRoleState extends IPaginationBaseState{
  models: any;
};


export class ChooseRole extends React.Component<IChooseRoleProps, IChooseRoleState> {
  applicantAssessmentService: ApplicantAssessmentService;
  constructor(props) {
    super(props);
    this.state = {...getSortState(this.props.location, ITEMS_PER_PAGE), models: []};

  }
  componentDidMount() {
   console.log("landing");
  }
  registerApplicant = (event) => {
    this.props.history.push({pathname:'/account/register',state:{role: AUTHORITIES.APPLICANT}});
  }
  registerHirer = (event) => {
    this.props.history.push({pathname:'/account/register',state:{role: AUTHORITIES.HIRER}});
  }
  render(){

    return (
        <div style={{marginTop: "80px"}} className={'mx-4'}>
          <Card className="col-xs-10 col-lg-6" style={{height:350}}>
            <Row style={{textAlign:'center', marginTop:20}}>
              <Col md="12">
                <h2 style={{fontSize:25}}>
                  <Translate contentKey="evaluateyouApp.landing.home.chooseRole"></Translate>
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{marginTop:20}}>

              <Col md="4" xs={12} className="text-center mt-4">
                <div style={{display:'flex',flexDirection:'column'}}>
                  <Button color="secondary" type="button"
                          onClick={(event) => this.registerApplicant(event)}>
                    <Translate contentKey="evaluateyouApp.landing.candidate"></Translate>
                  </Button>
                  <div className="mt-sm-3 mt-md-3">
                    <span>You are sitting for assessments</span>
                  </div>
                </div>
              </Col>

              <Col md="4" xs={12} className="text-center mt-4">
                <div style={{display:'flex',flexDirection:'column'}}>
                  <Button color="primary" type="button" onClick={(event) => this.registerHirer(event)}>
                    <Translate contentKey="evaluateyouApp.landing.employer"></Translate>
                  </Button>
                  <div  className="mt-sm-3 mt-md-3">
                    <span>You are creating assessments to evaluate individuals</span>
                  </div>
                </div>
              </Col>

            </Row>
          </Card>
        </div>
    );
  }


};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ChooseRole);
