import './training.css';

import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Col, Label, Row} from 'reactstrap';
import {Button, Card, CardActions, CardContent, Typography} from "@material-ui/core"

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './training.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {TextFormat} from 'react-jhipster';
import {ITraining} from 'app/shared/model/training.model';
import {displayImage} from 'app/shared/util/common-utils';
import {quillFormats, quillModules} from 'app/shared/util/ui-utils';
import ReactQuill from 'react-quill';
import 'highlight.js/styles/monokai-sublime.css';

export interface ITrainingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface ITrainingDetailState {
    duration:number,
    training:ITraining,
}

export class TrainingDetail extends React.Component<ITrainingDetailProps,ITrainingDetailState> {

    constructor(props) {
        super(props);
        this.state = {
            duration: 0,
            training: {}
        };

        this.props.getEntity(this.props.match.params.id);
    }

    componentWillReceiveProps(updatedProps) {

        if (updatedProps.trainingEntity && updatedProps.trainingEntity.id) {
            this.setState({
                training: updatedProps.trainingEntity,
                duration: updatedProps.trainingEntity.trainingSchedules && updatedProps.trainingEntity.trainingSchedules.length>0?updatedProps.trainingEntity.trainingSchedules[0].sessions.flatMap(session=>session.dates).reduce((acc, curr) => acc + parseInt(curr?curr.duration||0:0,10), 0):0
            });
        }
    }

    render() {
        const {trainingEntity} = this.props;

        return (
            <div className="px-5 mb-5">
                <Card>
                    <CardContent>
                        <Row>
                            <Col md={8} xs={12}>
                                <Row className="justify-content-left" style={{paddingTop: 40}}>
                                    <Col sm="12" className="title">
                                      <span className="text-left" style={{textAlign: 'left'}}>
                                        <b>{this.state.training.name}</b>
                                      </span>
                                    </Col>
                                </Row>


                                <Row className="content ml-2 mt-3">
                                    <Col sm={8} className="justify-content-left">
                                        {
                                            this.state.training.description &&
                                          <ReactQuill
                                            modules={quillModules}
                                            formats={quillFormats} theme="bubble"
                                            value={this.state.training.description}
                                            readOnly={true}
                                          />
                                        }

                                    </Col>
                                </Row>

                                <Row className="content mt-4 ml-4">
                                    <Label style={{textAlign: 'left', fontSize: 24}}>
                                        Outcome
                                    </Label>
                                </Row>

                                <Row className="content mt-1 ml-4">
                                    {
                                        this.state.training.outcome &&
                                      <ReactQuill
                                        modules={quillModules}
                                        formats={quillFormats} theme="bubble"
                                        value={this.state.training.outcome}
                                        readOnly={true}
                                      />
                                    }
                                </Row>

                                 <br/><br/>
                              <div style={{textAlign:"left"}}>
                              <Button component={Link} to={`/training-list`}>
                                Back
                              </Button>
                              </div>
                            </Col>

                            <Col md={4} xs={12}>
                                <Card className="cardColor1">
                                    <CardContent className="cardColor1">
                                        {
                                            trainingEntity &&
                                          <Row className="w-100">
                                            <img className="px-0 py-0" style={{
                                                padding: "10px",
                                                width: 378,
                                                height:252,
                                                outline: 'none'
                                            }} src={trainingEntity.image?displayImage(trainingEntity.image):'content/images/training-table.jpg'} alt="Training Table"/>
                                          </Row>
                                        }

                                    </CardContent>

                                    <CardContent className="cardColor1"
                                                 style={{paddingBottom: 0, paddingTop: 0}}>
                                        <Row className="cardContentTitle"
                                             style={{width: "100%"}}>
                                                <span className="cardContentTitle">{this.state.training.name}</span>
                                        </Row>

                                        <Row className="content mt-4 ml-4">
                                            <span className="text-left" style={{textAlign: 'left', fontSize: 16}}>
                                              <b>Schedule</b>
                                            </span>
                                        </Row>

                                        {
                                            this.state.training.trainingSchedules && this.state.training.trainingSchedules.length > 0 && this.state.training.trainingSchedules.map((trainingSchedule, idx) =>
                                                <Row key={idx} className="my-3">

                                                    <Col md={2}>
                                                        {trainingSchedule.scheduleName}
                                                    </Col>


                                                    <Col key={idx} md={10}>
                                                        {
                                                            trainingSchedule.sessions.map((session, idx2) => {
                                                                return (
                                                                    <Row key={idx2} >
                                                                        <Col md={5} className="align-items-start">{session.sessionName}</Col>
                                                                        <Col md={7}>
                                                                            {
                                                                                session.dates.map((date, idx3) => {

                                                                                    return (
                                                                                        <Row key={idx3} className="justify-content-center ">

                                                                                            <TextFormat
                                                                                            type="date"
                                                                                            value={date.startDateTime}
                                                                                            format={APP_DATE_FORMAT}/>

                                                                                            <Row className="align-items-center my-auto">
                                                                                                <div>
                                                                                                    <Typography variant="subtitle2" style={{display: 'inline-block'}}>TO</Typography>
                                                                                                </div>
                                                                                            </Row>

                                                                                            <TextFormat
                                                                                                type="date"
                                                                                                value={date.endDateTime}
                                                                                                format={APP_DATE_FORMAT}/></Row>)
                                                                                })
                                                                            }
                                                                        </Col>
                                                                     </Row>);
                                                            })
                                                        }

                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        <Row className="content mt-4 ml-4">
                                            <span className="text-left" style={{textAlign: 'left', fontSize: 16}}>
                                              <b>Duration</b>
                                            </span>
                                        </Row>
                                        <Row className="content mt-1 ml-4">
                                            <span className="text-left" style={{textAlign: 'left', fontSize: 16}}>
                                              {this.state.duration}&nbsp;hours
                                            </span>
                                        </Row>
                                    </CardContent>

                                    <CardActions className="cardColor1" style={{
                                        paddingBottom: 30,
                                        paddingLeft: 20,
                                        paddingRight: 20
                                    }}>
                                        {
                                            this.state.training.externalLink?
                                                <Button
                                                    variant="contained"
                                                    className="mt-3 w-100"
                                                    onClick={() => window.open(
                                                        this.state.training.externalLink,
                                                        '_blank' // <- This is what makes it open in a new window.
                                                    )}
                                                    color="primary"
                                                >
                                                            <span className="cardButtonDescription">View Now</span>
                                                </Button>
                                                :
                                                this.state.training.youTubeLink?
                                                <Button
                                                    variant="contained"
                                                    className="mt-3 w-100"
                                                    onClick={() => this.props.history.push(`/training/${this.state.training.id}/watch`)}
                                                    color="primary"
                                                >
                                                            <span className="cardButtonDescription">Watch Now</span>
                                                </Button>
                                                    :
                                                <Button
                                                    variant="contained"
                                                    className="mt-3 w-100"
                                                    onClick={() => this.props.history.push(`/training/${this.state.training.id}/enroll`)}
                                                    color="primary"
                                                >
                                                    <span className="cardButtonDescription">Enrol Now</span>
                                                </Button>
                                        }

                                    </CardActions>

                                </Card>
                            </Col>

                        </Row>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({
                             training,
                         }: IRootState) => ({
    trainingEntity: training.entity,
});

const mapDispatchToProps = {
    getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingDetail);
