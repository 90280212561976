import './landing.scss';

import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {AUTHORITIES} from "app/config/constants";
import {hasAnyAuthority} from "app/shared/auth/private-route";


export interface ILandingProps extends StateProps, RouteComponentProps<{ id: string }> {
}

export interface ILandingState extends IPaginationBaseState {
    models: any;
};


export class Landing extends React.Component<ILandingProps, ILandingState> {
    applicantAssessmentService: ApplicantAssessmentService;

    constructor(props) {
        super(props);
        this.state = {...getSortState(this.props.location, ITEMS_PER_PAGE), models: []};

    }

    componentDidMount() {
    }

    register = () => {
        this.props.history.push({pathname:'/choose-role'});
    }

    login = () =>{
      this.props.history.push('/login');
    }

    render() {
      const { location, isAuthenticated, isHirer, isAdmin } = this.props;
      const { from } = location.state || { from: { pathname: isHirer ? '/assessment' : (isAdmin ? "/audit": "/dashboard"), search: location.search } };
      if (isAuthenticated) {
        return <Redirect to={from} />;
      }else{
        return (
          <Row className="w-100" style={{marginTop:-50}}>
            <Row style={{backgroundColor:'#C1B9EA', minHeight:545}} className="justify-content-center w-100">
              <Col md="6" xs={12} style={{textAlign:'right'}}>
                <div style={{height:'100%', display:'flex', flexDirection:'column',justifyContent:'center'}}>
                  <div style={{marginTop: 10}}>
                        <span className="hero-main-title" style={{overflow:'wrap'}}>
                        Streamline Your Hiring Process
                        </span>
                  </div>
                  <div style={{marginTop: 10}}>
                                <span className="hero-main-subtitle">
                                Create customized evaluations, ensuring you find the perfect fit for your team.
                                </span>
                  </div>
                  <div style={{marginTop: 10, marginBottom: 10, display:'flex',alignItems:'flex-end',flexDirection:'column'}}>
                    <div className="rounded-button" style={{width: 220}}>
                      <a className="d-flex align-items-center nav-link" onClick={this.register}>
                        GET STARTED
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" xs={12} className="my-auto py-3">
                <img src="content/images/people-presenting.png" style={{width:'60%'}}/>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{backgroundColor:'#ffffff', minHeight:200}}>
              <Col md="12">
                <span className="hero-main-title">How we enable you?</span>
              </Col>
            </Row>

            <Row className="w-100 py-5">
              <Col md={6} xs={12} className="my-auto my-3">
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Powerful Features, Simplified Hiring</span>
                </Row>
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-subtitle">From customizable assessment templates to in-depth analytics, our platform offers a suite of tools designed to optimize your recruitment strategy.</span>
                </Row>
              </Col>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/woman-holding-phone.png" width="263"/>
              </Col>
            </Row>

            <Row className="w-100 py-5" style={{backgroundColor:'#ffffff'}}>
              <Col md={6} xs={12} className="my-auto my-3">
                <img src="content/images/landing-graph.png" width="110"/>
              </Col>
              <Col md={6} xs={12}>
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-title">Assess, Analyze, Achieve</span>
                </Row>
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-subtitle">In three easy steps, transform your hiring process:
                    <br/>1) Create tailored assessments<br/> 2) Distribute to candidates<br/> 3) Analyze results to make informed hiring decisions.</span>
                </Row>
              </Col>
            </Row>

            <Row className="w-100 py-5">
              <Col md={6} xs={12} className="my-auto py-3">
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Ready to Revolutionize Your Hiring?</span>
                </Row>
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-subtitle">Join the ranks of successful companies optimizing their hiring processes. Get started today with our free trial and experience the difference firsthand.</span>
                </Row>
              </Col>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/people-in-discussion.png" style={{width:'60%'}}/>
              </Col>
            </Row>

            <Row className="w-100 py-5" style={{backgroundColor:'#ffffff'}}>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/2-people-with-book.png" width="338"/>
              </Col>
              <Col md={6} xs={12} className="my-auto my-3">
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-title">Flexible Pricing for Every Business Size</span>
                </Row>
                <Row className="w-100 px-5 py-3">
                  <span className="hero-main-subtitle">Explore our range of pricing options designed to accommodate businesses of all sizes. Get exactly what you need to enhance your hiring process without overstepping your budget</span>
                </Row>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{minHeight:200}}>
              <Col md="12" className="my-auto my-3 py-3">
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-title">Have Questions? We&apos;ve Got Answers</span>
                </Row>
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-subtitle">Dive into our FAQ section for more information on how our platform can be tailored to fit your unique hiring needs.</span>
                </Row>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{backgroundColor:'#C1B9EA', minHeight:200}}>
              <Col md="12"  className="my-auto my-3 py-3">
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-title">Get in Touch</span>
                </Row>
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-subtitle">Have more questions or need a personalized walkthrough? Our team is here to help. Contact us today to learn how we can support your hiring goals.</span>
                  <a className="d-flex align-items-center nav-link" onClick={this.register}>
                    <div className="rounded-button">
                      Get Started
                    </div>
                  </a>
                </Row>
              </Col>
            </Row>

          </Row>

        );
      }

    }


};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
    isAdmin: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]):false,
    isApplicant: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.APPLICANT]):false,
    isHirer: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.HIRER]):false,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Landing);
