import {Moment} from 'moment';
import {IAssessmentEmail} from 'app/shared/model/assessment-email.model';
import {IAssessmentAudit} from 'app/shared/model/assessment-audit.model';
import {IEntity} from 'app/shared/model/entity.model';

export interface IAssessment extends IEntity {
  id?: string;
  title?: string;
  fullNames?: string;
  description?: string;
  percent?: number;
  expiryDate?: Moment;
  logoContentType?: string;
  logo?: any;
  tags?: string;
  allowViewScore?: boolean;
  showAnswers?: boolean;
  difficulty?: string;
  totalApplicants?: number;
  totalScore?: number;
  totalMaxScore?: number;
  assessmentEmails?: IAssessmentEmail[];
  assessmentAudits?: IAssessmentAudit[];
  randomize?: boolean;
  allowPublic?: boolean;
}

export const defaultValue: Readonly<IAssessment> = {
  allowViewScore: false,
  showAnswers: false
};
