import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Table} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './candidate-assessment-experience.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';

export interface ICandidateAssessmentExperienceProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ICandidateAssessmentExperienceState = IPaginationBaseState;

export class CandidateAssessmentExperience extends React.Component<
  ICandidateAssessmentExperienceProps,
  ICandidateAssessmentExperienceState
> {
  state: ICandidateAssessmentExperienceState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { candidateAssessmentExperienceList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="candidate-assessment-experience-heading">
          <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.home.title">Candidate Assessment Experiences</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.home.createLabel">
              Create a new Candidate Assessment Experience
            </Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {candidateAssessmentExperienceList && candidateAssessmentExperienceList.length > 0 ? (
            <Table responsive aria-describedby="candidate-assessment-experience-heading">
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('year')}>
                    <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.year">Year</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.audit">Audit</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.assessment">Assessment</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {candidateAssessmentExperienceList.map((candidateAssessmentExperience, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${candidateAssessmentExperience.id}`} color="link" size="sm">
                        {candidateAssessmentExperience.id}
                      </Button>
                    </td>
                    <td>{candidateAssessmentExperience.year}</td>
                    <td>
                      {candidateAssessmentExperience.audit ? (
                        <Link to={`audit/${candidateAssessmentExperience.audit.id}`}>{candidateAssessmentExperience.audit.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {candidateAssessmentExperience.assessment ? (
                        <Link to={`assessment/${candidateAssessmentExperience.assessment.id}`}>
                          {candidateAssessmentExperience.assessment.id}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${candidateAssessmentExperience.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${candidateAssessmentExperience.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${candidateAssessmentExperience.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.home.notFound">
                No Candidate Assessment Experiences found
              </Translate>
            </div>
          )}
        </div>
        <div className={candidateAssessmentExperienceList && candidateAssessmentExperienceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={this.state.activePage}
              onSelect={this.handlePagination}
              maxButtons={5}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalItems}
            />
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ candidateAssessmentExperience }: IRootState) => ({
  candidateAssessmentExperienceList: candidateAssessmentExperience.entities,
  totalItems: candidateAssessmentExperience.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateAssessmentExperience);
