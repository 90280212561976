import axios from 'axios';
import {Storage, translate} from 'react-jhipster';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';
import {getSession} from 'app/shared/reducers/authentication';
import {IUser} from 'app/shared/model/user.model';

export const ACTION_TYPES = {
  UPDATE_ACCOUNT: 'account/UPDATE_ACCOUNT',
  GET_FULL_PROFILE: 'account/GET_FULL_PROFILE',
  HAS_RESUME: 'account/HAS_RESUME',
  PROFILE_COMPLETION: 'account/PROFILE_COMPLETION',
  RESET: 'account/RESET'
};

const initialState = {
  loading: false,
  profile: {} as IUser,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  hasResume: false,
  profileCompletion: 0
};

export type SettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: SettingsState = initialState, action): SettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_FULL_PROFILE):
    case REQUEST(ACTION_TYPES.HAS_RESUME):
    case REQUEST(ACTION_TYPES.PROFILE_COMPLETION):
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.GET_FULL_PROFILE):
    case FAILURE(ACTION_TYPES.HAS_RESUME):
    case FAILURE(ACTION_TYPES.PROFILE_COMPLETION):
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateFailure: true
      };
    case SUCCESS(ACTION_TYPES.PROFILE_COMPLETION):
      return {
        ...state,
        loading: false,
        profileCompletion: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_FULL_PROFILE):
      return {
        ...state,
        loading: false,
        profile: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false
      };
    case SUCCESS(ACTION_TYPES.HAS_RESUME):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
        hasResume: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = 'api/account';

export const saveAccountSettings = account => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.post(apiUrl, account),
    meta: {
      successMessage: translate('settings.messages.success')
    }
  });

  if (Storage.session.get(`locale`)) {
    Storage.session.remove(`locale`);
  }

  await dispatch(getSession());
};

export const saveProfile = account => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.put(`${apiUrl}/profile`, account),
    meta: {
      successMessage: translate('settings.messages.success')
    }
  });

  dispatch(getSession());
};

export const getFullProfile = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.GET_FULL_PROFILE,
    payload: axios.get(`${apiUrl}/full-profile`)
  });
};

export const hasResume = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.HAS_RESUME,
    payload: axios.get(`${apiUrl}/has-resume`)
  });
};

export const getProfileCompletion = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.PROFILE_COMPLETION,
    payload: axios.get(`${apiUrl}/profile-completion`)
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
