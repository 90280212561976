// @flow
import * as React from 'react';
import {Card} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {Col, Row} from 'reactstrap';


export const RegisterSuccess = () => {

  const location = useLocation();
  location.state;
  console.log(location.state);

  return (
    <Row className={'justify-content-center'}>
      <Col xs={10} lg={6}>
        <Card style={{marginTop: 80}} className={'px-5 py-5'}>
          <h2 id="register-title" style={{textAlign: 'center'}}>
            Successfully Registered!
          </h2>
          <div className={'my-4'}>
            <p style={{textAlign: 'center'}}>
              An activation email will be sent to your email: <b>{location.state.email}</b>.
            </p>
          </div>
          <div>
            <p style={{textAlign: 'center'}}>
              Kindly activate your account by clicking on the link in the email.
            </p>
          </div>
        </Card>
      </Col>
    </Row>

  );
};
