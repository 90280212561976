// @flow
import * as React from 'react';
import {Card, Typography} from '@material-ui/core';
import {IRootState} from 'app/shared/reducers';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import {IAssessment} from 'app/shared/model/assessment.model';
import {AssessmentTable} from 'app/modules/candidate-training-dashboard/components/assessment-table';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';
import {RouteComponentProps} from 'react-router-dom';


export interface Props extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

type State = {
    assessment:IAssessment;
    completedAssessments:IApplicantAssessmentDTO[];
};

export class ApplicantResultsByAssessment extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        console.log(this.props);
        console.log(props.location.state);
        this.state = {
            assessment: props.location.state.assessment,
            completedAssessments: props.location.state.completedAssessments
        }
    }

    render() {
        return (
            <div className="w-100">
                <Row className="justify-content-center">
                    <Col xs={11} md={10}>
                        <Card className="py-4">
                            <Row>
                                <Col xs={10} md={10}>
                                    <Typography variant="h5" className="p-3 pull-left">
                                        {this.state.assessment.title}
                                    </Typography>
                                    <Typography variant="subtitle1" className="pull-left ml-3" style={{textAlign:'left'}}>
                                        {this.state.assessment.description}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className={'mt-2'}>
                                <AssessmentTable key={this.state.completedAssessments.length} account={this.props.account} assessments={this.state.completedAssessments}/>
                            </Row>

                        </Card>
                    </Col>
                </Row>

            </div>

        );
    };
};

const mapStateToProps = (storeState:IRootState) => ({
    account: storeState.authentication.account,
});

const mapDispatchToProps = {  };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicantResultsByAssessment);
