import {IAudit} from 'app/shared/model/audit.model';
import {IAssessment} from 'app/shared/model/assessment.model';

export interface ICandidateAssessmentExperience {
  id?: string;
  year?: number;
  audit?: IAudit;
  assessment?: IAssessment;
}

export const defaultValue: Readonly<ICandidateAssessmentExperience> = {};
