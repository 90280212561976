import axios from 'axios';
import {cleanEntity} from 'app/shared/util/entity-utils';

const apiUrl = 'api/report';

export class ReportService {
  constructor() {}
  async downloadPDF(entity) {
    const requestUrl = `${apiUrl}/downloadPDF?assessmentId=${entity.assessmentId}&applicantLogin=${entity.applicantLogin}`;
    return axios
      .post(requestUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default ReportService;
