import {Moment} from 'moment';

export interface ICreditHistory {
  id?: string;
  amount?: number;
  transactionId?: string;
  transactionTime?: Moment;
  status?: string;
  data?: string;
}

export const defaultValue: Readonly<ICreditHistory> = {};
