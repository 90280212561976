import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {hot} from 'react-hot-loader';

import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import {setLocale} from 'app/shared/reducers/locale';
import {Header} from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import {AUTHORITIES} from 'app/config/constants';
import AppRoutes from 'app/routes';
import TopBar from 'app/shared/components/top-bar';
import {createStyles, createTheme, makeStyles, ThemeProvider} from '@material-ui/core';

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}


export const defaultAppTheme = createTheme({
    palette: {
      primary: {
        main: "#806FE4"
      },
      secondary: {
        main: "#E1A4C4"
      }
    },
    typography: {
        fontFamily:'Montserrat',
        subtitle1:{
            color: '#000000',
            opacity: 0.5
        },
        subtitle2:{
            color: '#000000',
            opacity: 0.5,
            fontSize: 10
        }

    }
});

const useStyles = makeStyles(() =>
    createStyles({
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: defaultAppTheme.spacing(0, 1),
            // necessary for content to be below app bar
            ...defaultAppTheme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: defaultAppTheme.spacing(3),
        },
    }),
);

export const App = (props: IAppProps) => {
    const [userTheme,setUserTheme] = React.useState<any>(defaultAppTheme);

  useEffect(() => {
    props.getSession();
  }, []);



  const paddingTop = '0px';
  return (
    <Router basename={baseHref}>
      <ThemeProvider theme={userTheme}>
      <div className={`app-container ${props.isAuthenticated?'flex-row':'flex-column'}`} style={{ paddingTop, minHeight:800 }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={props.isAuthenticated}
            isAdmin={props.isAdmin}
            isApplicant={props.isApplicant}
            isHirer={props.isHirer}
            creditVal={props.creditVal}
            currentLocale={props.currentLocale}
            onLocaleChange={props.setLocale}
            ribbonEnv={props.ribbonEnv}
            isInProduction={props.isInProduction}
            isSwaggerEnabled={props.isSwaggerEnabled}
            account={props.account}
          />
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
            <ErrorBoundary>
                <div style={{display:'flex',flexDirection:'column'}}>
                    {
                        props.isAuthenticated &&
                      <TopBar></TopBar>
                    }

                    <AppRoutes />
                </div>
            </ErrorBoundary>

        </div>
      </div>
      <div>
          <Footer/>
      </div>

      </ThemeProvider>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale, credit }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]):false,
  isApplicant: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.APPLICANT]):false,
  isHirer: authentication.account?hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.HIRER]):false,
  creditVal: credit.credit,
  account: authentication.account,
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled
});

const mapDispatchToProps = { setLocale, getSession};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(module)(App));
