import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  updateEntity
} from './applicant-assessment.reducer';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {CONSTANTS} from 'app/shared/util/global.constants';
import TextField from '@material-ui/core/TextField';

export interface IApplicantAssessmentInformProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApplicantAssessmentInformState {
  isNew: boolean;
  assessmentId: string;
  time: number;
  spentTime: number;
  currentIdx: number;
  questions: any;
  models: any;
  currentQuestion: any;
  allowNext: boolean;
  hasError: boolean;
  fullName: string;
}

export class ApplicantAssessmentInform extends React.Component<IApplicantAssessmentInformProps, IApplicantAssessmentInformState> {
  applicantAssessmentService: ApplicantAssessmentService;
  ONE_SECOND = 1000;
  ONE_MINUTE = 60 * this.ONE_SECOND;

  constructor(props) {
    super(props);
    this.state = {
      assessmentId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      time: 0,
      spentTime: 0,
      currentIdx: 0,
      questions: [],
      models: [],
      currentQuestion: {},
      allowNext: false,
      hasError: false,
      fullName: this.props.account.fullName
    };
  }

  componentDidMount() {
    this.applicantAssessmentService = new ApplicantAssessmentService();
    const examObj = localStorage.getItem(CONSTANTS.EXAM_DATA + this.props.match.params.id);
    console.log(examObj);
    if(examObj){
      const exam = JSON.parse(examObj);

      const _questions = exam.questions;
      const _models = exam.models;
      const _currentIdx = exam.currentIdx;
      const _currentQuestion = _questions[_currentIdx];
      const _spentTime = exam.time;

      this.setState({models: _models, questions: _questions, currentQuestion: _currentQuestion, currentIdx: _currentIdx, spentTime: _spentTime});

      this.calculateTimeToDoExam(_questions);
      this.storeExam();
    }else{
      this.applicantAssessmentService.generateExam(this.props.match.params.id).then(data =>{
          const _questions = data;
          const _models = [];
          for(let i = 0; i < _questions.length; i++){
            const question = {id: _questions[i].id, answers: {}, score: _questions[i].score};
            _models.push(question);
          }

          const _currentQuestion = _questions[0];
          this.setState({models: _models, questions: _questions, currentQuestion: _currentQuestion});

          this.calculateTimeToDoExam(_questions);

          console.log(`Storing exam - inform`);
          this.storeExam();

      });
    }
  }
  storeExam = () =>{
    const obj = {id: this.props.match.params.id, time: this.state.spentTime, currentIdx: this.state.currentIdx, questions: this.state.questions, models: this.state.models};
    localStorage.setItem(CONSTANTS.EXAM_DATA + obj.id, JSON.stringify(obj));
  }

  calculateTimeToDoExam = (questions) =>{
    let minutes = 0, seconds = 0;

    for(let i = 0; i < questions.length; i++){
      const time = questions[i].time.split(":");
      minutes += parseInt(time[0], 10);
      seconds += parseInt(time[1], 10);
    }
    const totalTime = this.ONE_MINUTE * minutes + this.ONE_SECOND * seconds;
    let examTime = Math.floor(totalTime/this.ONE_MINUTE);
    const mod = examTime % 5;
    if(mod !== 0){
      examTime += (5 - mod);
    }

    this.setState({time: examTime});

  }

  setModelValue = (field, event) => {
    if(field === 'fullName'){
      this.setState({fullName: event.target.value});
    }else{
      this.setState({allowNext: event.target.checked});
    }
  }

  handleNext = (event) =>{
    const{fullName} = this.state;
    if(fullName !== ""){
      localStorage.setItem(CONSTANTS.FULL_NAME, fullName);
      this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/experience');
    }

  }

  back = (event) =>{
    this.props.history.push('/dashboard');
  }

  render() {
    const { isNew, time, allowNext, hasError } = this.state;

    return (
      <div>
        {hasError ? (
          <Row className="justify-content-center">
            <Col md="12">
              <h2 style={{color: "red"}}>
                <Translate contentKey="evaluateyouApp.applicantAssessment.exam.messages.notInvited">
                </Translate>
              </h2>
            </Col>
          </Row>
        ) :(
          <div>
            <Row>
              <Col md="12">
                <h4>
                  <b className="pull-left">
                    <Translate contentKey="evaluateyouApp.applicantAssessment.assessmentInformation.title">
                      Create or edit a ApplicantAssessment
                    </Translate>
                  </b>
                </h4>
              </Col>
            </Row>
            <Card className="main-card">
              <Row className="justify-content-center">
                <Col md="8">
                  <ol>
                    <li className={"exam-instruction"}>
                      <span>You have <span style={{fontWeight:'bold',fontSize:'150%'}}>{time}</span> minutes to complete the test. </span>
                    </li>
                    <li className={"exam-instruction"}>
                      <span>You will not be penalized for wrong answers.</span>
                    </li>
                    <li className={"exam-instruction"}>
                      <span> Do not close the browser for the duration of the test.</span>
                    </li>
                    <li className={"exam-instruction"}>
                      <span>Your time will begin when you hit the &quot;START&quot; button.</span>
                    </li>
                  </ol>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="8" className="marginLeft40">

                      <TextField id="full-name"
                                 variant="filled"
                                 fullWidth
                                 value={this.state.fullName}
                                 name="name"
                                 helperText="Full Name e.g. John Francis Smith"
                                 onChange={(event) => this.setModelValue("fullName", event)}/>


                    <div className="pretty p-default p-round p-thick" style={{marginTop:20}}>
                      <input type="radio" onChange={(event) => this.setModelValue("allowViewScore", event)}/>
                      <div className="state p-primary-o">
                        <label>
                          <Translate contentKey="evaluateyouApp.applicantAssessment.assessmentInformation.confirmToNext">Allow View Score</Translate>
                        </label>
                      </div>
                    </div>
                </Col>
              </Row>
              <Row className="justify-content-center marginTop">
                <Col md="12">
                  <Button className="pull-left" color="primary" id="back" type="button" onClick={(event) => this.back(event)}>
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </Button>
                  <Button className="pull-right btn-primary" id="save-entity" type="button" disabled={!allowNext} onClick={(event) => this.handleNext(event)}>
                    <Translate contentKey="entity.action.continue">Continue</Translate>
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>)}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentInform);
