import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import {Link} from 'react-router-dom';

interface ProgressBarProps{
    currentStep:ProgressBarOption;
    stepOptions: ProgressBarOption[];
    isStepCompleted:Function
}

export class ProgressBarOption{
    step:number;
    stepTitle:string;
    renderStep:Function;
    percentageCompletion?:number;
    link?:string;
}


export class ProgressBar extends React.Component<ProgressBarProps> {
    constructor(props){
        super(props);

    }

    render() {
        return (
            <div className="horizontal-steps">

                {this.props.stepOptions.map((stepOption,index) =>(

                    <div key={index} style={{display:'flex',flexDirection:'row'}}>
                        <div className="step-main">
                            <div className={"step-item " + (this.props.currentStep.step === stepOption.step ? 'active' : '') + (this.props.isStepCompleted(stepOption.step)? ' complete' : '') }>
                                {
                                    stepOption.percentageCompletion?
                                        stepOption.percentageCompletion === 100?
                                        <span> {stepOption.percentageCompletion===100?<DoneIcon style={{color:'white'}}/>:`${stepOption.percentageCompletion} %`} </span>
                                        :
                                        <Link to={stepOption.link?stepOption.link:''}>{`${stepOption.percentageCompletion} %`}</Link>
                                    :
                                        <Link to={stepOption.link?stepOption.link:''}>{stepOption.step}</Link>
                                }
                            </div>
                            <div className="step-text">{stepOption.stepTitle}</div>
                        </div>
                        { (this.props.stepOptions.length === index + 1)?null :
                            <div className="flex-column">
                                <svg className="step-line">
                                    <line x1="0" y1="0" x2="200" y2="0"/>
                                </svg>
                            </div>

                        }

                    </div>
                ))}


            </div>
        )
    }
}

