import './progressive-candidate-dashboard.scss';
import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {getProfileCompletion} from 'app/modules/account/settings/settings.reducer';
import {getMyTrainings} from 'app/entities/training/training.reducer';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {ProgressBar, ProgressBarOption} from 'app/entities/assessment/steps/progress-bar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {getAssessmentsByTargetRole} from 'app/entities/assessment/assessment.reducer';
import {SkillsetCard} from 'app/modules/progressive-candidate-dashboard/components/skillset-card';
import {
  getCompletedAssessmentsByCurrentLogin
} from 'app/entities/applicant-assessment/applicant-assessment.reducer';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';
import {AssessmentTable} from 'app/modules/candidate-training-dashboard/components/assessment-table';
import {Button, Typography} from '@material-ui/core';

export interface ProgressiveCandidateDashboardProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface ProgressiveCandidateDashboardState extends IPaginationBaseState {
    assessments: IApplicantAssessmentDTO[];
    assignedAssessments: IApplicantAssessmentDTO[];
    completedSteps: number[];
    profileCompletion: number;
    userTrainings: IUserTraining[];
    currentStepOption: ProgressBarOption;
    steps:ProgressBarOption[];

    closeProfilePrompt: boolean;
}


export class ProgressiveCandidateDashboard extends React.Component<ProgressiveCandidateDashboardProps, ProgressiveCandidateDashboardState> {
    applicantAssessmentService: ApplicantAssessmentService;


    constructor(props) {
        super(props);

        console.log(this.props.account);

        this.state = {
            ...getSortState(this.props.location, ITEMS_PER_PAGE),
            closeProfilePrompt: false,
            assessments: [],
            profileCompletion: this.props.profileCompletion,
            userTrainings: this.props.userTrainings,
            completedSteps:[],
            currentStepOption: {
                step: 1,
                stepTitle: 'Profile',
                renderStep: null
            },
            assignedAssessments: [],
            steps: [
                {
                    step: 1,
                    stepTitle: 'Profile',
                    renderStep: null,
                    percentageCompletion: 0,
                    link:'/account/profile'
                },
                {
                    step: 2,
                    stepTitle: 'Competency',
                    renderStep: null,
                    percentageCompletion: 0,
                    link:'/dashboard'
                },
                {
                    step: 3,
                    stepTitle: 'Job Fitability',
                    percentageCompletion: 0,
                    renderStep: null
                },
                {
                    step: 4,
                    stepTitle: 'Portfolio',
                    percentageCompletion: 0,
                    renderStep: null
                },
            ]
        };

    }

    componentDidMount() {
        this.props.getProfileCompletion();
        this.props.getMyTrainings();
        this.props.getAssessmentsByTargetRole();
        this.props.getCompletedAssessmentsByCurrentLogin();

        this.applicantAssessmentService = new ApplicantAssessmentService();

        this.applicantAssessmentService.getApplicantAssessmentEmailDTOs().then(data => {
            if (data === null) {
                data = [];
            }
            this.setState({assignedAssessments: data});
        });
    }

    componentWillReceiveProps(updatedProps) {
        if(updatedProps.profileCompletion){
            this.setState({profileCompletion: updatedProps.profileCompletion});
            this.setState({steps: this.state.steps.map((step, index) => {
                    if(index === 0){
                        console.log(`percentage completion: ${updatedProps.profileCompletion}`);
                        step.percentageCompletion = updatedProps.profileCompletion;
                    }
                    return step;
                })});
        }

        if(updatedProps.userTrainings){
            this.setState({userTrainings: updatedProps.userTrainings});
        }

    }

    isStepCompleted(step:number):boolean{
        return this.state.completedSteps.includes(step);
    }


    render() {
        return (
            <div>
              {/* create reactjs modal */}
              <Modal isOpen={this.state.profileCompletion < 66 && !this.state.closeProfilePrompt}>
                <ModalHeader >
                  Your Profile is Incomplete
                </ModalHeader>
                <ModalBody>
                  Please complete your profile so that we can match you to your Dream Job.  <Button onClick={()=>this.props.history.push('/account/profile')}>Take me there</Button>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={()=>this.setState({closeProfilePrompt:true})}>Close</Button>
                </ModalFooter>
              </Modal>


                <Row>
                    <ProgressBar currentStep={this.state.currentStepOption} isStepCompleted={(step)=>this.isStepCompleted(step)} stepOptions={this.state.steps}/>
                </Row>

                <Row className="justify-content-center mb-3">
                    <Col xs={10}>
                        <div style={{padding:10, backgroundColor:'#D5D0EF'}}>
                            <CheckCircleIcon/> Complete your skill competency by getting a passing score in our assessments to increase your chances of being shortlisted and ranked.
                        </div>

                    </Col>
                </Row>

                <Row className="justify-content-center w-100"
                         style={{alignItems: "center", paddingBottom: 60, textAlign: 'left'}}>
                    <Col xs={10}>
                        <SkillsetCard assessments={this.props.assessments} completedAssessments={this.props.completedAssessments} targetRole={this.props.account.targetRole}/>
                    </Col>
                </Row>

                {
                    this.state.assignedAssessments && this.state.assignedAssessments.length > 0 &&
                  <Row className="justify-content-center w-100"
                       style={{alignItems: "center", paddingBottom: 60, textAlign: 'left'}}>
                    <Col xs={10}>
                      <div>
                        <Row>
                          <Typography variant="h5" className="px-3 py-1">Assessments assigned to you</Typography>
                        </Row>
                        <Row>
                          <AssessmentTable key={this.state.assignedAssessments.length} account={this.props.account}
                                           assessments={this.state.assignedAssessments}/>
                        </Row>
                      </div>

                    </Col>
                  </Row>
                }

            </div>
        );
    }


}

const mapStateToProps = storeState => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
    profileCompletion: storeState.settings.profileCompletion,
    userTrainings: storeState.training.userTrainings,
    assessments: storeState.assessment.entities,
    completedAssessments: storeState.applicantAssessment.entities
});

const mapDispatchToProps = {
    getProfileCompletion,
    getMyTrainings,
    getAssessmentsByTargetRole,
    getCompletedAssessmentsByCurrentLogin
};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProgressiveCandidateDashboard);
