import {CONSTANTS} from 'app/shared/util/global.constants';

export const escapeHtml = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }

  string = string.replace(/\n/gi, '<br/>');
  // string = string.replace(/&/g, "&amp;");
  string = string.replace(/</gi, '&lt;');
  string = string.replace(/>/gi, '&gt;');
  string = string.replace(/"/g, '&quot;');
  string = string.replace(/'/g, '&apos;');

  return string;
};
export const unescapeHtml = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  string = string.replace(/&amp;/g, '&');
  string = string.replace(/&apos;/g, "'");
  string = string.replace(/&quot;/g, '"');
  string = string.replace(/&gt;/gi, '>');
  string = string.replace(/&lt;/gi, '<');
  // escape <script>
  string = string.replace(/<script/gi, '&lt;script');
  string = string.replace(/<\/script>/gi, '&lt;/script&gt;');
  return string;
};
export const unescapeHtmlAndNewLine = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  string = string.replace(/&amp;/g, '&');
  string = string.replace(/&apos;/g, "'");
  string = string.replace(/&quot;/g, '"');
  string = string.replace(/&gt;/gi, '>');
  string = string.replace(/&lt;/gi, '<');
  string = string.replace(/<br\/>/gi, '\n');
  // escape <script>
  string = string.replace(/<script/gi, '&lt;script');
  string = string.replace(/<\/script>/gi, '&lt;/script&gt;');
  return string;
};

export const isBlank = string => {
  return !string || /^\s*$/.test(string);
};

export const unescapeNewLine = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  string = string.replace(/&lt;br&gt;/g, '<br/>');
  string = string.replace(/\n/g, '<br/>');
  return string;
};
export const escapeHtmlIgnoreBr = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }

  string = string.replace(/\n/gi, '<br/>');
  // string = string.replace(/&/g, "&amp;");
  string = string.replace(/</gi, '&lt;');
  string = string.replace(/>/gi, '&gt;');
  string = string.replace(/"/g, '&quot;');
  string = string.replace(/'/g, '&apos;');

  string = string.replace(/&lt;br\/&gt;/g, '<br/>');
  string = string.replace(/&lt;br&gt;/g, '<br/>');
  return string;
};
export const escapeNewLine = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  if (typeof string !== 'string') {
    return string;
  }
  string = string.replace(/<br>/g, '\n');
  string = string.replace(/<br\/>/g, '\n');
  return string;
};

export const escapeString = string => {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  if (typeof string !== 'string') {
    return string;
  }
  string = string.replace(/&lt;br&gt;/g, '<br>');
  string = string.replace(/\n/g, '<br>');
  string = escape(string);
  return string;
};

export const validateEmail = email => {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getUrlVars = () => {
  const vars = {};
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.forEach((value, key) => {
    vars[key] = value;
  });
  return vars;
};

export const toBase64 = (file: File, cb: Function) => {
  const fileReader: FileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = e => {
    const base64Data = e.target['result'].substr(e.target['result'].indexOf('base64,') + 'base64,'.length);
    cb(base64Data);
  };
};

export const displayHtml = html => {
  return { __html: html ? unescapeHtml(html) : '' };
};

export const displayImage = image => {
  if (image.indexOf('data:image/') < 0) {
    return 'data:image/;base64,' + image;
  } else {
    return image;
  }
};

export const isAssessmentOngoing = (assessmentId: string) => {
  const exam = localStorage.getItem(CONSTANTS.EXAM_DATA + assessmentId);
  if (exam != null) {
    return true;
  } else {
    return false;
  }
};
