import {ICourse} from 'app/shared/model/course.model';

export interface IMajor {
  id?: number;
  name?: string;
  description?: string;
  course?: ICourse;
}

export const defaultValue: Readonly<IMajor> = {};
