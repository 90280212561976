import moment from 'moment';
import Moment from 'moment-timezone';

import {
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT_2,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_MONTH_YEAR,
  APP_LOCAL_DATETIME_FORMAT_Z
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeFromServerToMonthYear = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT_MONTH_YEAR) : null);



export const convertDateFromServer = date => (date ? moment(date).format(APP_LOCAL_DATE_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const convertDateTimeToYYYYMMDD = date => (date ? moment(date, APP_LOCAL_DATE_FORMAT_2).toDate() : null);

// convert date to YYYY-MM-DD in string format
export const convertDateToYYYYMMDDString = date => (date ? moment(date, APP_LOCAL_DATE_FORMAT_2).format(APP_LOCAL_DATE_FORMAT_2) : null);


export const TIME_FORMAT = { ddMMMyyyyZ: 'DD MMM YYYY hh:mm:ss Z', DDMMMYYYY: 'DD-MMM-YYYY' };

export const toTimeZoneWithFormat = (time, zone, format) => {
  if (!time) return '';

  zone = zone != null ? zone : 'Asia/Kuala_Lumpur';
  return Moment(time.toString())
    .tz(zone)
    .format(format);
};

export const inPast = (time, zone) => {
  if (!time) return '';

  zone = zone != null ? zone : 'Asia/Kuala_Lumpur';
  return Moment(time.toString())
    .tz(zone)
    .isBefore(Moment.tz(zone));
};

export const diffSecondWithToday = (time, zone, unit) => {
  if (!time) return '';

  zone = zone != null ? zone : 'Asia/Kuala_Lumpur';
  const date1 = Moment(time.toString()).tz(zone);
  const date2 = Moment.tz(zone);

  const value = date1._d.getTime() - date2._d.getTime();
  // var day = 1000*60*60*24;
  return value;
};

export const getTimeBySecondInText = second => {
  const secondInDay = 1000 * 60 * 60 * 24;
  const HOUR = 1000 * 60 * 60;
  let days = second / secondInDay;

  days = Math.ceil(days);

  if (days === 1) {
    days = second / HOUR;
    days = Math.ceil(days);
    return 'In ' + days + ' hours';
  }
  if (days === 2) {
    return 'Tomorrow';
  }
  if (days > 2) {
    days = Math.ceil(days);
    return 'In ' + days + ' days';
  }
};
