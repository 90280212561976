import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';

import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  updateEntity
} from './applicant-assessment.reducer';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';

export interface IApplicantAssessmentExamResultProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApplicantAssessmentExamResultState {
  assessmentId: string;
}

export class ApplicantAssessmentExamResult extends React.Component<IApplicantAssessmentExamResultProps, IApplicantAssessmentExamResultState> {
  applicantAssessmentService: ApplicantAssessmentService;

  constructor(props) {
    super(props);
    this.state = {
      assessmentId: '0'
    };
  }

  componentDidMount() {
  }
  handleNext = (event) =>{
    this.props.history.push('/dashboard');
  }

  render() {
    return (
      <div>
        <Row className="pull-left">
          <Col md="12">
            <h2>
              <b>
              <Translate contentKey="evaluateyouApp.applicantAssessment.exam.title">
              </Translate>
              </b>
            </h2>
          </Col>
        </Row>
        <Card className="main-card">
          <Row className="justify-content-center">
            <Col md="8">
              <p> <Translate contentKey="evaluateyouApp.applicantAssessment.exam.messages.completed1"></Translate></p>
              <p> <Translate contentKey="evaluateyouApp.applicantAssessment.exam.messages.completed2"></Translate></p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <Button color="primary" onClick={(event) => this.handleNext(event)}>
                <Translate contentKey="entity.action.ok">OK</Translate>
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentExamResult);
