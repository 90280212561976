import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row, Table} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './education.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';


export interface IEducationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEducationState = IPaginationBaseState;

export class Education extends React.Component<IEducationProps, IEducationState> {
  state: IEducationState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { educationList, match, totalItems } = this.props;
    return (
      <div>
        <Row>
          <Col md="9">
            <b className="pull-left">
              Education
            </b>
            <Card className="main-card">
              <Row>
                <Col md="12">
                  <div>
                    <h2 className="pull-right">
                      <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="evaluateyouApp.education.home.createLabel">Add Education</Translate>
                      </Link>
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    {educationList && educationList.length > 0 ? (
                      <Table responsive aria-describedby="education-heading">
                        <thead>
                        <tr>
                          <th className="hand" onClick={this.sort('level')}>
                            <Translate contentKey="evaluateyouApp.education.level">level</Translate> <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={this.sort('yearFrom')}>
                            <Translate contentKey="evaluateyouApp.education.yearFrom">yearFrom</Translate> <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={this.sort('yearTo')}>
                            <Translate contentKey="evaluateyouApp.education.yearTo">yearTo</Translate> <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={this.sort('institution')}>
                            <Translate contentKey="evaluateyouApp.education.institution">institution</Translate> <FontAwesomeIcon icon="sort" />
                          </th>
                          <th />
                        </tr>
                        </thead>
                        <tbody>
                        {educationList.map((education, i) => (
                          <tr key={`entity-${i}`}>
                            <td>{education.level}</td>
                            <td>{education.yearFrom}</td>
                            <td>{education.yearTo}</td>
                            <td>{education.institution}</td>
                            <td className="text-right">
                              <div className="btn-group flex-btn-group-container">
                                <Button tag={Link} to={`${match.url}/${education.id}`} color="info" size="sm">
                                  <FontAwesomeIcon icon="eye" />{' '}
                                  <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                                </Button>
                                <Button tag={Link} to={`${match.url}/${education.id}/edit`} color="primary" size="sm">
                                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                                  <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                                </Button>
                                <Button tag={Link} to={`${match.url}/${education.id}/delete`} color="danger" size="sm">
                                  <FontAwesomeIcon icon="trash" />{' '}
                                  <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div className="alert alert-warning">
                        <Translate contentKey="evaluateyouApp.education.home.notFound">No education found</Translate>
                      </div>
                    )}
                  </div>
                  <div className={educationList && educationList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                      <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                      <JhiPagination
                        activePage={this.state.activePage}
                        onSelect={this.handlePagination}
                        maxButtons={5}
                        itemsPerPage={this.state.itemsPerPage}
                        totalItems={this.props.totalItems}
                      />
                    </Row>
                  </div>
                </Col>
              </Row>

            </Card>
          </Col>

        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ education }: IRootState) => ({
  educationList: education.entities,
  totalItems: education.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Education);

