import './candidate-training-dashboard.scss';
import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {getProfileCompletion} from 'app/modules/account/settings/settings.reducer';
import {getMyTrainings} from 'app/entities/training/training.reducer';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {ProfileCompletion} from 'app/modules/candidate-training-dashboard/components/profile-completion';
import {
  UpcomingTrainingDates
} from 'app/modules/candidate-training-dashboard/components/upcoming-training-dates';
import {
  CompletedAssessments
} from 'app/modules/candidate-training-dashboard/components/completed-assessments';
import {AssessmentTable} from 'app/modules/candidate-training-dashboard/components/assessment-table';
import {CompletedTraining} from 'app/modules/candidate-training-dashboard/components/completed-training';
import {InProgressTraining} from 'app/modules/candidate-training-dashboard/components/inprogress-training';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';

export interface CandidateTrainingDashboardProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface CandidateTrainingDashboardState extends IPaginationBaseState {
    assessments: IApplicantAssessmentDTO[];
    profileCompletion: number;
    userTrainings: IUserTraining[];
}


export class CandidateTrainingDashboard extends React.Component<CandidateTrainingDashboardProps, CandidateTrainingDashboardState> {
    applicantAssessmentService: ApplicantAssessmentService;

    constructor(props) {
        super(props);

        this.state = {
            ...getSortState(this.props.location, ITEMS_PER_PAGE),
            assessments: [],
            profileCompletion: this.props.profileCompletion,
            userTrainings: this.props.userTrainings
        };

    }

    componentDidMount() {
        console.log('retrieving data!');
        this.props.getProfileCompletion();
        this.props.getMyTrainings();

        this.applicantAssessmentService = new ApplicantAssessmentService();

        this.applicantAssessmentService.getApplicantAssessmentEmailDTOs().then(data => {
            if (data === null) {
                data = [];
            }
            this.setState({assessments: data});
        });
    }

    componentWillReceiveProps(updatedProps) {
        console.log('receiving updated props');
        if(updatedProps.profileCompletion){
            console.log('profile completion: ' + updatedProps.profileCompletion);
            this.setState({profileCompletion: updatedProps.profileCompletion});
        }

        if(updatedProps.userTrainings){
            console.log('user trainings updated');
            console.log(updatedProps.userTrainings);
            this.setState({userTrainings: updatedProps.userTrainings});
        }

    }


    render() {
        return (
            <div>
                <Row className="mb-5">
                    <Col className="align-self-stretch my-0" sm={4}
                         style={{textAlign: 'left', alignSelf: 'stretch', display: 'flex'}}>
                        <ProfileCompletion profileCompletion={this.state.profileCompletion}/>
                    </Col>

                    <Col className="align-self-stretch my-0" sm={4}
                         style={{textAlign: 'left', alignSelf: 'stretch', display: 'flex'}}>
                        <UpcomingTrainingDates userTrainings={this.state.userTrainings}/>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col className="align-self-stretch my-0" sm={4}>
                        <CompletedTraining userTrainings={this.state.userTrainings}/>
                    </Col>

                    <Col className="align-self-stretch my-0" sm={4}>
                        <InProgressTraining userTrainings={this.state.userTrainings}/>
                    </Col>

                    <Col className="align-self-stretch my-0" sm={4}>
                        <CompletedAssessments assessments={this.state.assessments}/>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}
                         style={{alignItems: "center", paddingBottom: 60, textAlign: 'left'}}>
                        <AssessmentTable account={this.props.account}
                                         title={'Assessments'}
                                         assessments={this.state.assessments}/>
                    </Col>
                </Row>
            </div>
        );
    }


}

const mapStateToProps = storeState => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
    profileCompletion: storeState.settings.profileCompletion,
    userTrainings: storeState.training.userTrainings,
});

const mapDispatchToProps = {
    getProfileCompletion,
    getMyTrainings
};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CandidateTrainingDashboard);
