import {IEntity} from 'app/shared/model/entity.model';

export interface ITopUp extends IEntity {
  id?: string;
  createdBy?: string;
  amount?: number;
  note?: string;
}

export const defaultValue: Readonly<ITopUp> = {};
