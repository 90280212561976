import {IAuditQuestion} from 'app/shared/model/audit-question.model';

export interface IQuestionFile {
  id?: string;
  name?: string;
  order?: number;
  dataContentType?: string;
  data?: any;
  auditQuestion?: IAuditQuestion;
}

export const defaultValue: Readonly<IQuestionFile> = {};
