import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Card, Row, Table} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  TextFormat,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './coupon.reducer';
import {DD_MMM_YYYY} from 'app/config/constants';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';

export interface ICouponProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ICouponState = IPaginationBaseState;

export class Coupon extends React.Component<ICouponProps, ICouponState> {
  state: ICouponState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { couponList, match, totalItems } = this.props;
    return (
      <Card className="main-card">
        <div>
          <h2 id="coupon-heading">
            <Translate contentKey="evaluateyouApp.coupon.home.title">Coupons</Translate>
            <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="evaluateyouApp.coupon.home.createLabel">Create a new Coupon</Translate>
            </Link>
          </h2>

          <div className="table-responsive">
            {couponList && couponList.length > 0 ? (
              <Table responsive aria-describedby="coupon-heading">
                <thead>
                  <tr>
                    <th className="hand" onClick={this.sort('id')}>
                      <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={this.sort('expiryDate')}>
                      <Translate contentKey="evaluateyouApp.coupon.expiryDate">Expiry Date</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={this.sort('credit')}>
                      <Translate contentKey="evaluateyouApp.coupon.credit">Credit</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={this.sort('usedBy')}>
                      <Translate contentKey="evaluateyouApp.coupon.usedBy">Used By</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={this.sort('usageLimit')}>
                      <Translate contentKey="evaluateyouApp.coupon.usageLimit">Usage Limit</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {couponList.map((coupon, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                          {coupon.id}
                        <CopyToClipboard text={coupon.id} onCopy={() => toast.success('Coupon ID copied to clipboard!')}>
                          <Button color="link" size="sm">
                            <FontAwesomeIcon icon={faCopy} style={{color:'#000000',opacity:0.5}}/>
                          </Button>
                        </CopyToClipboard>
                      </td>
                      <td>
                        <TextFormat type="date" value={coupon.expiryDate} format={DD_MMM_YYYY} />
                      </td>
                      <td>{coupon.credit}</td>
                      <td>{coupon.usedBy}</td>
                      <td>{coupon.usageLimit}</td>
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`${match.url}/${coupon.id}`} color="info" size="sm">
                            <FontAwesomeIcon icon="eye" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${coupon.id}/edit`} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${coupon.id}/delete`} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="evaluateyouApp.coupon.home.notFound">No Coupons found</Translate>
              </div>
            )}
          </div>
          <div className={couponList && couponList.length > 0 ? '' : 'd-none'}>
            <Row className="justify-content-center">
              <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={this.state.activePage}
                onSelect={this.handlePagination}
                maxButtons={5}
                itemsPerPage={this.state.itemsPerPage}
                totalItems={this.props.totalItems}
              />
            </Row>
          </div>

        </div>
      </Card>
    );
  }
}

const mapStateToProps = ({ coupon }: IRootState) => ({
  couponList: coupon.entities,
  totalItems: coupon.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);
