import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  TextFormat,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './assessment.reducer';
import {IAssessment} from 'app/shared/model/assessment.model';
import {AUTHORITIES, DD_MMM_YYYY} from 'app/config/constants';
import {IApplicantAssessment} from 'app/shared/model/applicant-assessment.model';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import AssessmentService from 'app/shared/service/assessment.service';
import AssessmentEmailService from 'app/shared/service/assessment-email.service';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {
  convertDateTimeToServer,
  inPast,
  TIME_FORMAT,
  toTimeZoneWithFormat
} from "app/shared/util/date-utils";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {getCredit} from "app/entities/credit/credit.reducer";
import {displayImage, validateEmail} from "app/shared/util/common-utils";
import ReportService from "app/shared/service/report.service";

export interface IAssessmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string, from:string }> {}

export interface IAssessmentDetailState extends IPaginationBaseState{
  model: IAssessment;
  applicantAssessments: IApplicantAssessment[];
  totalItems: number;
  isExpired: boolean;
  showSuccessForm: boolean;
  showConfirmRemoveCandidate:boolean;
  showInviteCandidateForm: boolean;
  activeApplicantAssessment: IApplicantAssessment;
  emails: any;
  examURL: any;
}

export class AssessmentDetail extends React.Component<IAssessmentDetailProps, IAssessmentDetailState> {
  assessmentService: AssessmentService;
  assessmentEmailService: AssessmentEmailService;
  reportService: ReportService;
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      model: {},
      applicantAssessments: [],
      totalItems: 0,
      isExpired: false,
      showSuccessForm: false,
      showConfirmRemoveCandidate: false,
      showInviteCandidateForm: false,
      activeApplicantAssessment: {},
      emails: [],
      examURL: ""
    };
    this.assessmentService = new AssessmentService();
    this.assessmentEmailService = new AssessmentEmailService();
    this.reportService = new ReportService();
  }
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    this.assessmentService.getEntity(this.props.match.params.id).then( response => {
      console.log(response);
      this.setState({model: response, isExpired: inPast(response.expiryDate, null)});
    });

    this.getEntities();
  }
  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    // this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=` + this.props.account.login);

      this.assessmentEmailService.loadByAssessment(this.props.match.params.id,activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=` + this.props.account.login).then(response => {
        console.log(response);
        const totalItems = parseInt(response["headers"]['x-total-count'], 10);
        const assessments = response["data"];
        this.setState({totalItems: totalItems, applicantAssessments: assessments});
      });
  };

  showTime = (time) => {
    const toString =  toTimeZoneWithFormat(time, this.props.account.timeZone, TIME_FORMAT.ddMMMyyyyZ); // timezone
    return toString;
  }

  showScore = (value) => {
    return Math.round(value*100)/100;
  }

  getStatusText = (item) =>{
    let text;
    if(item.score){
      if((item.score * 100 / item.maxScore) > item.assessmentPercent){
        text =  <div><FontAwesomeIcon icon={["far","check-circle"]} style={{color: "#3B86FF", fontSize: "20px"}} title="Passed"/></div>;
      }else{
        text =  <div><FontAwesomeIcon icon={["far","times-circle"]} style={{color: "#FF3BB7", fontSize: "20px"}} title="Failed"/></div>;
      }
    }
    return text;
  }

  totalQuestions = () => {
    let value = 0;
    const {model} = this.state;
    if(model && model.assessmentAudits){
      for(let i = 0; i < model.assessmentAudits.length; i++){
        value += model.assessmentAudits[i].numOfQuestions;
      }
    }
    return value;
  }

  viewExam = (item) => {
    this.props.history.push('/applicant-assessment/' + item.assessmentId + "/" + item.createdBy + "/" + this.props.match.params.from + "/exam-view");
  }
  downloadExam = (item) =>{
    console.log(item);
    this.reportService.downloadPDF({applicantLogin: item.createdBy, assessmentId: item.assessmentId}).then(data =>{
      const link = document.createElement('a');
      link.href = 'data:application/octet-stream;base64,' + data["data"];
      link.download = data["headers"].filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  goBack = () =>{
    if(this.props.match.params.from === 'l'){
      this.props.history.push('/assessment');
    }else{
      this.props.history.push('/allAssessment');
    }
  }

  sendInvite = (item) =>{
    let expiryDate = new Date();
    const {model} = this.state;
    if(model.expiryDate){
      try{
        expiryDate = convertDateTimeToServer(model.expiryDate); // .split("T")[0];
      }catch (error){
        console.log(error);
      }
    }
    this.assessmentService.sendInviteEmail({emails: [item.email], assessmentId: model.id, expiryDate: expiryDate}).then(response =>{
      console.log(response);
      this.setState({examURL: response + `/applicant-assessment/${model.id}/inform`, showSuccessForm: true});
    });
  }
  handleRemoveCandidate = (item) =>{
    this.setState({showConfirmRemoveCandidate: true, activeApplicantAssessment: item});
  }

  handleShowCandidate = () =>{
    this.setState({showInviteCandidateForm: true});
  }

  hideDialog =() =>{
    this.setState({showSuccessForm: false});
  }
  hideConfirmRemoveCandidate =() =>{
    this.setState({showConfirmRemoveCandidate: false, activeApplicantAssessment: {}});
  }

  hideInviteCandidateForm =() =>{
    this.setState({showInviteCandidateForm: false, emails: []});
  }

  renderSuccess(){
    const {showSuccessForm, examURL} = this.state;
    return (<Modal isOpen={showSuccessForm} toggle={() => this.hideDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideDialog()}>Message</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Translate contentKey="evaluateyouApp.assessment.messages.sentMail"></Translate>
          </Col>
        </Row>
        <Row>
          <Col>
            {examURL}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideDialog()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }

  removeCandidate = () =>{
    const {activeApplicantAssessment, applicantAssessments} = this.state;
    const indexOf = applicantAssessments.indexOf(activeApplicantAssessment);
    this.assessmentEmailService.removeCandidate(activeApplicantAssessment.id).then( data =>{
      this.props.getCredit();
      if(indexOf >= 0){
        applicantAssessments.splice(indexOf, 1);
        this.setState({applicantAssessments: applicantAssessments, showConfirmRemoveCandidate: false, activeApplicantAssessment: {}});
      }
    });
  }

  inviteCandidate = () =>{
    let expiryDate = new Date();
    const {model, emails} = this.state;
    if(model.expiryDate){
      try{
        expiryDate = convertDateTimeToServer(model.expiryDate); // .split("T")[0];
      }catch (error){
        console.log(error);
      }
    }
    if(emails.length === 0){
      return;
    }

    const newAssessmentEmails = [];
    for(let i = 0; i < emails.length; i++){
      newAssessmentEmails.push({email: emails[i]});
    }
    const obj: any = Object.assign({}, model);
    obj.assessmentEmails = newAssessmentEmails;
    obj.expiryDate = expiryDate;
    this.assessmentEmailService.saveInvite(obj).then(response =>{
      console.log(response);
      let {applicantAssessments} = this.state;
      applicantAssessments = applicantAssessments.concat(response.forms);
      this.setState({examURL: response.url + `/applicant-assessment/${model.id}/inform`, showSuccessForm: true, showInviteCandidateForm: false, applicantAssessments: applicantAssessments});
    });
  }

  renderConfirmDeleteCandidate(){
    const {showConfirmRemoveCandidate, activeApplicantAssessment} = this.state;
    return (<Modal isOpen={showConfirmRemoveCandidate} toggle={() => this.hideConfirmRemoveCandidate()} backdrop={true}>
      <ModalHeader toggle={() => this.hideConfirmRemoveCandidate()}>Message</ModalHeader>
      <ModalBody>
        <Translate contentKey="evaluateyouApp.assessment.messages.removeCandidate" interpolate={{user:activeApplicantAssessment.email}}>Use Coupon</Translate>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => this.hideConfirmRemoveCandidate()}> <Translate contentKey="entity.action.cancel"></Translate></Button>{' '}
        <Button color="primary" onClick={() => this.removeCandidate()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }

  changeEmails = chips =>{
    this.setState({emails: chips });
  }

  validTagInput = (value) =>{
    const {emails, applicantAssessments} = this.state;
    const indexOf = emails.indexOf(value);
    if(indexOf === -1 && validateEmail(value)){
      for(let i = 0; i < applicantAssessments.length; i++){
        const applicantAssessment = applicantAssessments[i];
        if(applicantAssessment.email === value){
          return false;
        }
      }
      return true;
    }
    return false;
  }

  renderInviteCandidate(){
    const {showInviteCandidateForm, emails} = this.state;
    return (<Modal isOpen={showInviteCandidateForm} toggle={() => this.hideInviteCandidateForm()} backdrop={true}>
      <ModalHeader toggle={() => this.hideInviteCandidateForm()}>Invite Candidate</ModalHeader>
      <ModalBody>
        <ReactTagInput
          tags={emails} placeholder="Type Email and Press Enter"
          onChange={(newTags) => this.changeEmails(newTags)}
          validator={ (value) => this.validTagInput(value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => this.hideInviteCandidateForm()}> <Translate contentKey="entity.action.cancel"></Translate></Button>{' '}
        <Button color="primary" onClick={() => this.inviteCandidate()}> <Translate contentKey="entity.action.invite"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }

  renderApplicantAssessments(){
    const {applicantAssessments, totalItems, isExpired} = this.state;
    const {match, account} = this.props;
    const className = 'show';
    return (<div className={className}>
      <div className="table-responsive" id="hoverMenu">
        {applicantAssessments && applicantAssessments.length > 0 ? (
          <Table responsive aria-describedby="assessment-heading">
            <thead>
            <tr>
              <th className="hand" onClick={this.sort('aa.score')}>
                <Translate contentKey="evaluateyouApp.assessmentEmail.joinedDate">Title</Translate> <FontAwesomeIcon icon="sort"/>
              </th>
              <th className="hand" onClick={this.sort('aa.fullName')}>
                <Translate contentKey="evaluateyouApp.assessmentEmail.employeeName">Email</Translate> <FontAwesomeIcon
                icon="sort"/>
              </th>
              <th className="hand" onClick={this.sort('spentTime')}>
                <Translate contentKey="evaluateyouApp.assessmentEmail.duration">Duration</Translate>
                <FontAwesomeIcon
                  icon="sort"/>
              </th>
              <th className="hand" onClick={this.sort('aa.score')}>
                <Translate contentKey="evaluateyouApp.assessmentEmail.score">Score</Translate>
                <FontAwesomeIcon
                  icon="sort"/>
              </th>
              <th className="hand" onClick={this.sort('aa.score')}>
                <Translate contentKey="evaluateyouApp.assessmentEmail.status"></Translate>
                <FontAwesomeIcon
                  icon="sort"/>
              </th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {applicantAssessments.map((item, i) => (
              <tr key={`entity-${i}`}>
                <td>{this.showTime(item.createdDate)}</td>
                <td>{item.fullName != null ? item.fullName : item.email}</td>
                <td>
                  {item.score != null ? Math.floor(item.spentTime/60) + " mins" : ""}
                </td>
                <td>
                  {item.score != null ? this.showScore(item.score*100/item.maxScore) + "%" : ""}
                </td>
                <td>{this.getStatusText(item)}</td>
                <td style={{width: "300px"}}>
                  <div className="text-right hover-menu">
                    <div className="btn-group flex-btn-group-container">
                      {item.score != null ?
                        <div>
                          <Button tag={Link} to={{
                            pathname: `/applicant-assessment/${item.assessmentId}/${item.createdBy}/${this.props.match.params.from}/exam-view`,
                            state: {from: this.props.location}
                          }}>
                            <FontAwesomeIcon icon="eye"/>{' '}<br/>
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </Button>
                          <Button  onClick={() => this.downloadExam(item)} color="info" size="sm">
                            <FontAwesomeIcon icon="save"/>{' '}<br/>
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.download">Download</Translate>
                            </span>
                          </Button>
                        </div>
                      : ""}
                      {item.score == null && !isExpired && hasAnyAuthority(account.authorities,[AUTHORITIES.HIRER]) ?
                      <Button onClick={() => this.sendInvite(item)} color="primary" size="sm">
                        <FontAwesomeIcon icon="envelope"/>{' '}<br/>
                        <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.sendInvite">Send Invite</Translate>
                            </span>
                      </Button>
                        : ""}
                      {item.score == null && !isExpired && hasAnyAuthority(account.authorities,[AUTHORITIES.HIRER]) ?
                        <Button onClick={() => this.handleRemoveCandidate(item)} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash"/>{' '}<br/>
                          <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                        </Button>
                        : ""}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="evaluateyouApp.applicantAssessment.home.notFound">No Assessments found</Translate>
          </div>
        )}
      </div>
      <div className={applicantAssessments && applicantAssessments.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage}
                        i18nEnabled/>
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
            itemsPerPage={this.state.itemsPerPage}
            totalItems={totalItems}
          />
        </Row>
      </div>
      <div>
        {!isExpired && hasAnyAuthority(account.authorities,[AUTHORITIES.HIRER]) ?
          <Button onClick={() => this.handleShowCandidate()} color="primary" size="sm">
            <FontAwesomeIcon icon="plus"/>{' '}
            <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.addCandidate">Add Candidate</Translate>
                          </span>
          </Button>
          : ""}
      </div>
    </div>);

  }

  renderAudits = () =>{
    const {model} = this.state;

    return ( model && model.assessmentAudits ? model.assessmentAudits.map((item, i) => (
      <div key={item.id}>
        <span>{item.audit.name} ({item.numOfQuestions} questions)</span>
        <br/>
      </div>
    )) : <span></span>);

  }

  render() {
    const {model} = this.state;
    return (
      <Card className="main-card">
        {this.renderSuccess()}
        {this.renderConfirmDeleteCandidate()}
        {this.renderInviteCandidate()}
        <Row className="justify-content-center">
          <Col md="10">
            <h2>
              <b>{model.title}</b>
            </h2>
            <Row>
              <Col md="2">
                <div className="jh-entity-details">
                  <div>
                    {model.logo ? (
                      <div>
                        <span>
                          <img style={{padding: "10px", width: "100px"}} src={displayImage(model.logo)}/>
                        </span>
                      </div>
                    ) : <img src="content/images/assessment_logo.png"/>}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="jh-entity-details">
                  <div><b>{model.title}</b></div>
                  <div>{model.description}</div>
                  <div>
                    <b id="percent">
                      <Translate contentKey="evaluateyouApp.assessment.passPercentage">Percent</Translate>
                    </b>
                  </div>
                  <div>{model.percent}%</div>

                  <div>
                    <b id="allowViewScore">
                      <Translate contentKey="evaluateyouApp.assessment.allowViewScore">Allow View Score</Translate>
                    </b>
                  </div>
                  <div>{model.allowViewScore ? 'true' : 'false'}</div>
                  <div>
                    <b id="showAnswers">
                      <Translate contentKey="evaluateyouApp.assessment.showAnswers">Show Answers</Translate>
                    </b>
                  </div>
                  <div>{model.showAnswers ? 'true' : 'false'}</div>
                </div>
              </Col>
              <Col md="4">
                <div className="jh-entity-details">
                  <div>
                    <b id="categories">
                      <Translate contentKey="evaluateyouApp.assessment.categories"></Translate>
                    </b>
                  </div>
                  <div>
                    {this.renderAudits()}
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="jh-entity-details">
                  <div>
                    <b id="expiryDate">
                      <Translate contentKey="evaluateyouApp.assessment.expiresOn">Expiry Date</Translate>
                    </b>
                  </div>
                  <div>
                    <TextFormat value={model.expiryDate} type="date" format={DD_MMM_YYYY}/>
                  </div>
                </div>
              </Col>
            </Row>


          <div style={{margin: "10px"}}>
            {this.renderApplicantAssessments()}
          </div>
          </Col>
        </Row>
        <Row>
          <Col md="10">
            <Button onClick={() => this.goBack()} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
            </Button>
          </Col>
        </Row>
      </Card>

    );
  }
}

const mapStateToProps = ({ assessment, authentication }: IRootState) => ({
  assessmentEntity: assessment.entity,
  account: authentication.account
});

const mapDispatchToProps = { getEntity, getCredit };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentDetail);
