import {ITrainingSchedule} from 'app/shared/model/training-schedule.model';
import {IUserTraining} from 'app/shared/model/user-training.model';

export interface ITraining {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  price?: number;
  level?: string;
  tags?: string;
  image?: any;
  outcome?: string;
  externalLink?: string;
  youTubeLink?: string;
  userTrainings?: IUserTraining[];
  trainingSchedules?: ITrainingSchedule[];
}

export const defaultValue: Readonly<ITraining> = {};
