import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getCredit, getEntities} from './credit.reducer';
import {ICredit} from 'app/shared/model/credit.model';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import CreditService from "app/shared/service/credit.service";
import {CONSTANTS} from "app/shared/util/global.constants";
import {ICreditHistory} from "app/shared/model/credit-history.model";
import {TIME_FORMAT, toTimeZoneWithFormat} from "app/shared/util/date-utils";
import {getUrlVars} from "app/shared/util/common-utils";

export interface ICreditProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ICreditState extends IPaginationBaseState{
  creditHistoryList: ICreditHistory[] ;
  totalItems: number;
  model: ICredit;
  showSuccess: boolean;
  addedCredit: number;
  finishedLoading:boolean;
}

export class Credit extends React.Component<ICreditProps, ICreditState> {
  creditService: CreditService;
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      creditHistoryList: [],
      totalItems: 0,
      model: {},
      sort: 'transactionTime',
      order: 'desc',
      showSuccess: false,
      addedCredit: 0,
      finishedLoading:false
    };
    this.creditService = new CreditService();
  }
  componentDidMount() {
    const urlVars = getUrlVars();
    const paymentId = urlVars['paymentId'];
    const payerId = urlVars['PayerID'];
    if(paymentId){
      this.handlePaymentCallback(paymentId,payerId);
    }else{
      this.getEntities();
      this.creditService.getCredit().then( response2 =>{
        if(response2.status === CONSTANTS.SUCCESS){
          this.setState({model: response2.form});
        }
        this.setState({finishedLoading:true});
      });
    }

  }

  handlePaymentCallback = (paymentId:any, payerId: any) =>{

    // const token = urlVars['token'];
    this.creditService.completePayment({paymentId: paymentId, payerID: payerId}).then(
      response =>{
        console.log(response);
        if(response.status === 1){
          this.setState({addedCredit: response.creditAdded, showSuccess:true})
        }

        this.getEntities();

        this.creditService.getCredit().then( response2 =>{
          if(response2.status === CONSTANTS.SUCCESS){
            this.setState({model: response2.form});
          }

        });

        this.setState({finishedLoading:true});

      }
    )
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.creditService.getPayments(activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=${this.props.account.login}`).then(
      response =>{
        console.log(response);
        const paymentHistory = response["data"];
        const totalItems = parseInt(response["headers"]['x-total-count'], 10);
        this.setState({creditHistoryList: paymentHistory, totalItems: totalItems});
      }
    )
  };
  showTime = (time) =>{
    const toString =  toTimeZoneWithFormat(time, this.props.account.timeZone, TIME_FORMAT.ddMMMyyyyZ);
    return toString;

  }

  hidePrompt(){
    if(this.state.showSuccess){
      this.setState({showSuccess:false});
      this.props.history.push(`/credit?cacheBuster=${new Date().getTime()}`); // refresh
    }
  }

  showNoneFound(){
    return(
        <div className="alert alert-warning">
          <Translate contentKey="evaluateyouApp.creditHistory.home.notFound">No Credit Histories found</Translate>
        </div>
    );
  }

  render() {
    const { match } = this.props;
    const { creditHistoryList, totalItems, showSuccess, model, addedCredit } = this.state;
    return (
      <div>
        <Modal isOpen={showSuccess} toggle={() => this.hidePrompt()} backdrop={true}>
          <ModalHeader toggle={() => this.hidePrompt()}>
            <Translate contentKey="evaluateyouApp.paymentRequest.success"></Translate>
          </ModalHeader>

          <ModalBody id="evaluateyouApp.coupon.success">
            <Translate contentKey="evaluateyouApp.paymentRequest.successDetail" interpolate={{ addedCredits: addedCredit}}>
            </Translate>
          </ModalBody>

          <ModalFooter>
            <Button className="btn-primary" id="jhi-confirm-delete-assessment" color="danger"
                    onClick={() => this.hidePrompt()}>
              <Translate contentKey="entity.action.ok">OK</Translate>
            </Button>
          </ModalFooter>
        </Modal>


        <h4 className="pull-left" id="title-style" style={{marginLeft: 30}}>
          Credits
        </h4>

        <Card className="main-card" style={{width: '95%'}}>
          <Row className="marginBottom" style={{marginTop:20}}>
            <Col md="2">
                <div>
                  <b id="credit">
                    <Translate contentKey="evaluateyouApp.credit.available">Available</Translate>
                  </b>
                </div>
                <div>{model.credit}</div>
            </Col>
            <Col md="2">
              <div>
                  <b id="spentCredit">
                    <Translate contentKey="evaluateyouApp.credit.spent">Spent</Translate>
                  </b>
              </div>
              <div>{model.spentCredit}</div>
            </Col>
            <Col md="3">
                <div>
                  <b id="spentCredit">
                    <Translate contentKey="evaluateyouApp.credit.lastUsed">Last Used</Translate>
                  </b>
                </div>
                <div>{this.showTime(model.createdDate)}</div>
            </Col>
            <Col md="5">
              <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="evaluateyouApp.credit.home.createLabel">Create a new Credit</Translate>
              </Link>
            </Col>
          </Row>

          <Row style={{marginTop:30}}>
            <Col md="12">
              <Nav>
                <NavItem>
                  <NavLink className="tab-active">
                    <Translate contentKey="evaluateyouApp.credit.purchaseHistory">Purchase History</Translate>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab="1">
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="table-responsive">
                        {creditHistoryList && creditHistoryList.length > 0 ? (
                            <Table responsive aria-describedby="credit-history-heading">
                              <thead>
                              <tr>
                                <th className="hand" onClick={this.sort('transactionId')}>
                                  <Translate contentKey="evaluateyouApp.creditHistory.transactionId">Transaction Id</Translate>{' '}
                                  <FontAwesomeIcon icon="sort"/>
                                </th>
                                <th className="hand" onClick={this.sort('amount')}>
                                  <Translate contentKey="evaluateyouApp.creditHistory.amount">Amount</Translate> <FontAwesomeIcon
                                    icon="sort"/>
                                </th>
                                <th className="hand" onClick={this.sort('transactionTime')}>
                                  <Translate contentKey="evaluateyouApp.creditHistory.transactionTime">Transaction Time</Translate>{' '}
                                  <FontAwesomeIcon icon="sort"/>
                                </th>
                                <th className="hand" onClick={this.sort('status')}>
                                  <Translate contentKey="evaluateyouApp.creditHistory.status">Status</Translate> <FontAwesomeIcon
                                    icon="sort"/>
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              {creditHistoryList.map((creditHistory, i) => (
                                  <tr key={`entity-${i}`}>
                                    <td>{creditHistory.transactionId}</td>
                                    <td>{creditHistory.amount.toFixed(2)}</td>
                                    <td>{this.showTime(creditHistory.transactionTime)}</td>
                                    <td>{creditHistory.status}</td>
                                  </tr>
                              ))}
                              </tbody>
                            </Table>
                        ) : (
                            this.state.finishedLoading?this.showNoneFound():''
                        )}
                      </div>
                      <div className={creditHistoryList && creditHistoryList.length > 0 ? '' : 'd-none'}>
                        <Row className="justify-content-center">
                          <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage}
                                        i18nEnabled/>
                        </Row>
                        <Row className="justify-content-center">
                          <JhiPagination
                              activePage={this.state.activePage}
                              onSelect={this.handlePagination}
                              maxButtons={5}
                              itemsPerPage={this.state.itemsPerPage}
                              totalItems={totalItems}
                          />
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>





        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ credit, authentication }: IRootState) => ({
  creditList: credit.entities,
  totalItems: credit.totalItems,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities,
  getCredit
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Credit);
