import React from 'react';
import '../../assessment/assessment.scss';
import {Col, Row, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Button, FormControl, FormHelperText, InputLabel, Tooltip} from '@material-ui/core';
import {ITrainingSession} from 'app/shared/model/training-session.model';
import {isBlank} from "app/shared/util/common-utils";
import {toast} from 'react-toastify';
import {ITrainingStepperChildProps} from 'app/entities/training/training-stepper';
import {ITrainingSchedule} from 'app/shared/model/training-schedule.model';
import {countEnrollmentsInSession, hasEnrollments} from 'app/shared/util/training-utils';
import {WarningBubble} from 'app/shared/components/warning-bubble';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';


interface TrainingSessionState {
  error: boolean;
  errorMessage: any;
  errors: string[];
  trainingSessionForm: ITrainingSession;
  trainingSessions: ITrainingSession[];
  trainingSchedules: ITrainingSchedule[];
  trainingScheduleId: number;
  addMode: boolean;

}

export class TrainingSession extends React.Component<ITrainingStepperChildProps, TrainingSessionState> {

  constructor(props) {
    super(props);
    console.log(this.props.model.trainingSchedules);
    console.log(this.props.model.trainingSchedules.length > 0 ? this.props.model.trainingSchedules.flatMap(ts => ts.sessions) : []);
    this.state = {
      error: false,
      errorMessage: {},
      trainingSchedules: this.props.model.trainingSchedules.length > 0 ? this.props.model.trainingSchedules:[],
      trainingSessions: this.props.model.trainingSchedules.length > 0 ? this.props.model.trainingSchedules.flatMap(ts => ts.sessions) : [],
      trainingSessionForm: {},
      trainingScheduleId: 0,
      errors: [],
      addMode: true
    };
  }

  saveAndGoNext(): void {
    // populate selected audits
    this.props.populateModel(this.state.trainingSessions);
    this.props.nextStep();
  }

  saveAndGoBack() {
    this.props.populateModel(this.state.trainingSessions);
    this.props.prevStep();
  }

  addOrUpdateSession() {
    console.log('adding session');
    // get the training schedule
    const trainingSchedule = this.props.model.trainingSchedules.find(ts => ts.id === this.state.trainingScheduleId);

    if (isBlank(  this.state.trainingSessionForm.sessionName )) {
      this.setState({
        error: true,
        errorMessage: {
          scheduleId: "Please select a schedule",
          sessionName: "Please enter a name for the session"
        }
      });
      return;
    }

    if (this.state.addMode) {
      // do not allow adding sessions with the same name
      const existingSession = this.state.trainingSessions.find(s => s.sessionName === this.state.trainingSessionForm.sessionName);
      if(existingSession){
        toast.error('Session with the same name already exists');
        return;
      }

      this.setState({
        trainingSessions: [...this.state.trainingSessions,
          {
            ...this.state.trainingSessionForm,
            id: (this.state.trainingSessions.length + 1),
            trainingSchedule: {id: trainingSchedule.id}
          }
        ]
      });
    } else { // update
      this.state.trainingSessions.forEach(ts => {
        if (ts.id === this.state.trainingSessionForm.id) {
          ts.sessionName = this.state.trainingSessionForm.sessionName;
        }
      });

      this.setState({trainingSessions: [...this.state.trainingSessions]});
    }
    this.setState({trainingSessionForm: {}, addMode: true});
  }

  editTrainingSession(id: number): void {
    this.setState({trainingSessionForm: this.state.trainingSessions.filter(e => e.id === id)[0], addMode: false});
    this.setState({trainingScheduleId: this.state.trainingSessions.filter(e => e.id === id)[0].trainingSchedule.id});
  }

  deleteTrainingSession(idx: number): void {
    this.state.trainingSessions.splice(idx, 1);
    this.setState({trainingSessions: [...this.state.trainingSessions]});
  }

  getScheduleNameById(id: number): string {
    console.log('schedule id: ' + id);
    return this.props.model.trainingSchedules.find(ts => ts.id === id).scheduleName;
  }


  render() {
    return (
      <div>
        <div className="page-title">
          <h4>
            <span><b>Sessions</b></span>
          </h4>
          <h6>
            A session represents a unique unit of training where a trainee is trained on specific topics. Different
            sessions are used to train a trainee on different topics.
            <br/><br/>Example: <b>Day 1 - Basics of Programming</b>
          </h6>
        </div>

        {
            hasEnrollments(this.props.model) &&
            <WarningBubble  description='Users have already been enrolled to this training. Some editable features will be limited'/>
        }

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/>
          </div>)}</label>) : (<span></span>)}


        {
          this.state.trainingSessions.length > 0 &&
          <Row>
            <Col md={12}>
              <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                <thead>
                <tr>
                  <th>Schedule</th>
                  <th>Session Name</th>
                  {
                      hasEnrollments(this.props.model) &&
                      <th>Enrollments</th>
                  }
                  <th style={{width: 100, textAlign: 'left'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.trainingSessions.map((trainingSession, idx) => (
                      <tr key={trainingSession.id}>
                        <td>{this.getScheduleNameById(trainingSession.trainingSchedule.id)}</td>
                        <td>{trainingSession.sessionName}</td>
                        {
                            hasEnrollments(this.props.model) &&
                            <td>{countEnrollmentsInSession(trainingSession,this.props.model.userTrainings)}</td>
                        }
                        <td style={{width: 100, textAlign: 'left'}}>
                          <div className="text-left">
                            <div className="btn-group flex-btn-group-container">
                              <Button className="mr-3" onClick={() => this.editTrainingSession(trainingSession.id)}>
                                <span className="d-none d-md-inline">Edit</span>
                              </Button>
                              {
                                countEnrollmentsInSession(trainingSession,this.props.model.userTrainings) === 0?
                                    <Button onClick={() => this.deleteTrainingSession(idx)}>
                                      <span className="d-none d-md-inline">Delete</span>
                                    </Button>
                                    :
                                    <Tooltip title="Delete disabled since there are enrollments associated with this session" arrow>
                                      <div style={{display:'flex'}} className="align-items-center justify-content-center w-100">
                                        <FontAwesomeIcon icon={faExclamationCircle} size={'1x'}/>
                                      </div>
                                    </Tooltip>
                              }
                            </div>
                          </div>
                        </td>
                      </tr>
                  ))
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        }


        <Row className="mt-4">
          <Col md={5}>
            <FormControl variant="filled" className="w-100">
              <InputLabel id="demo-simple-select-filled-label">Schedule</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={this.state.trainingScheduleId}
                onChange={(event) => this.setState({trainingScheduleId: event.target.value as number})}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {
                  this.props.model.trainingSchedules.map(trainingSchedule =>
                    <MenuItem key={trainingSchedule.id}
                              value={trainingSchedule.id}>{trainingSchedule.scheduleName}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText>Please select a schedule</FormHelperText>

            </FormControl>
          </Col>
          <Col md={5}>
            <TextField id="assessment-title"
                       label="Session Name"
                       variant="filled"
                       fullWidth
                       name="sessionName"
                       value={this.state.trainingSessionForm.sessionName ? this.state.trainingSessionForm.sessionName : ''}
                       onChange={(event) => this.setState({
                         trainingSessionForm: {
                           ...this.state.trainingSessionForm,
                           sessionName: event.target.value
                         }
                       })}
                       error={this.state.errorMessage.sessionName}
                       helperText="Please state a name for your training session."
                       required
            />

          </Col>
          <Col className="justify-content-center my-auto" md={2} style={{height:56}}>
            <Button onClick={() => this.addOrUpdateSession()}
                    className="btn-primary">
                        <span className="d-none d-md-inline">
                        {this.state.addMode ? 'Add' : 'Update'}
                        </span>
            </Button>
          </Col>
        </Row>



        <div className="mt-5 mb-4">
          <Button onClick={() => this.saveAndGoBack()} variant="contained" color="primary"
                  className="btn-primary">
            <FontAwesomeIcon icon="arrow-left"/>
            &nbsp;
            <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>
          <Button onClick={() => this.saveAndGoNext()} variant="contained" color="primary"
                  className="pull-right btn-primary">
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.next"></Translate>
                      </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    );
  }


}
