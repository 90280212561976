import axios from 'axios';
import {
  ICrudDeleteAction,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  IPayload
} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IAssessment} from 'app/shared/model/assessment.model';

export const ACTION_TYPES = {
  FETCH_ASSESSMENT_LIST: 'assessment/FETCH_ASSESSMENT_LIST',
  FETCH_ASSESSMENT: 'assessment/FETCH_ASSESSMENT',
  CREATE_ASSESSMENT: 'assessment/CREATE_ASSESSMENT',
  UPDATE_ASSESSMENT: 'assessment/UPDATE_ASSESSMENT',
  DELETE_ASSESSMENT: 'assessment/DELETE_ASSESSMENT',
  SET_BLOB: 'assessment/SET_BLOB',
  RESET: 'assessment/RESET',
  FETCH_SEARCH_ASSESSMENT_LIST: 'assessment/FETCH_SEARCH_ASSESSMENT_LIST'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAssessment>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type AssessmentState = Readonly<typeof initialState>;

type ICrudSearchGetAllAction<T> = (
  searchKey: string,
  page?: number,
  size?: number,
  sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

// Reducer

export default (state: AssessmentState = initialState, action): AssessmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ASSESSMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ASSESSMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ASSESSMENT):
    case REQUEST(ACTION_TYPES.UPDATE_ASSESSMENT):
    case REQUEST(ACTION_TYPES.DELETE_ASSESSMENT):
    case REQUEST(ACTION_TYPES.FETCH_SEARCH_ASSESSMENT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ASSESSMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ASSESSMENT):
    case FAILURE(ACTION_TYPES.CREATE_ASSESSMENT):
    case FAILURE(ACTION_TYPES.UPDATE_ASSESSMENT):
    case FAILURE(ACTION_TYPES.DELETE_ASSESSMENT):
    case FAILURE(ACTION_TYPES.FETCH_SEARCH_ASSESSMENT_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ASSESSMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ASSESSMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ASSESSMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_ASSESSMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ASSESSMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_SEARCH_ASSESSMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/assessments';

// Actions

export const getEntities: ICrudGetAllAction<IAssessment> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ASSESSMENT_LIST,
    payload: axios.get<IAssessment>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IAssessment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ASSESSMENT,
    payload: axios.get<IAssessment>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IAssessment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ASSESSMENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IAssessment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ASSESSMENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAssessment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ASSESSMENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const searchEntities: ICrudSearchGetAllAction<IAssessment> = (searchKey: string) => {
  const requestUrl = `${apiUrl}/search`;
  return {
    type: ACTION_TYPES.FETCH_SEARCH_ASSESSMENT_LIST,
    payload: axios.get<IAssessment>(`${requestUrl}/${searchKey}`)
  };
};

export const getAssessmentsByTargetRole = () => {
  const requestUrl = `${apiUrl}/current-user/by-target-role`;
  return {
    type: ACTION_TYPES.FETCH_SEARCH_ASSESSMENT_LIST,
    payload: axios.get<IAssessment>(`${requestUrl}`)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
