import '../assessor-dashboard.scss';
import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {getThisUserTrainings} from 'app/entities/training/training.reducer';
import {getAdminAssessmentEmails} from 'app/entities/assessment-email/assessment-email.reducer';
import {IAssessmentEmail} from "app/shared/model/assessment-email.model";
import {
  CandidateTableDetails
} from "app/modules/assessor-dashboard/components/candidate-table-details";
import moment from 'moment/moment.js';


export interface CandidateTableProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface CandidateTableState extends IPaginationBaseState {
  assessmentCandidates: IAssessmentEmail[];
}


export class CandidateTable extends React.Component<CandidateTableProps, CandidateTableState> {
  constructor(props) {

    super(props);
    let assessmentCandidates = this.props.location.state.assessmentCandidates;

    if(assessmentCandidates){
      const today = new Date();
      const lastWeek = new Date(moment().subtract(7, "days").format("YYYY-MM-DD "));

      assessmentCandidates = assessmentCandidates.filter(ut => new Date(ut.createdDate) >= lastWeek);


    }

    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      assessmentCandidates:assessmentCandidates,
    };

  }


  componentWillReceiveProps(updatedProps) {
    if(updatedProps.assessmentCandidates){
      this.setState({assessmentCandidates: updatedProps.assessmentCandidates});
    }
  }

  render() {
    return (
      <div>
        <Row className="mb-5">
          <Col className="align-self-stretch my-0" sm={4} style={{textAlign: 'left', alignSelf: 'stretch', display: 'flex'}}>
            <CandidateTableDetails assessmentCandidates={this.state.assessmentCandidates}/>
          </Col>
        </Row>
      </div>
    );
  }


}

const mapStateToProps = storeState => ({
  isAuthenticated: storeState.authentication.isAuthenticated,
  profileCompletion: storeState.settings.profileCompletion,

});

const mapDispatchToProps = {getAdminAssessmentEmails, getThisUserTrainings};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CandidateTable);
