import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row, Table} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  updateEntity
} from './applicant-assessment.reducer';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import AuditService from 'app/shared/service/audit.service';
import {IAudit} from 'app/shared/model/audit.model';
import TextField from '@material-ui/core/TextField';

export interface IApplicantAssessmentExperienceProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApplicantAssessmentExperienceState {
  isNew: boolean;
  models: any;
  yearsFilledIn: boolean;
}

export class ApplicantAssessmentExperience extends React.Component<IApplicantAssessmentExperienceProps, IApplicantAssessmentExperienceState> {

  auditService: AuditService;
  applicantAssessmentService: ApplicantAssessmentService;
  years: any;
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      models: [],
      yearsFilledIn: false
    };
    this.years = {};
  }

  componentDidMount() {
    this.auditService = new AuditService();
    this.applicantAssessmentService = new ApplicantAssessmentService();

    this.auditService.getByAssessment(this.props.match.params.id).then(data =>{
      this.setState({models: data});
    })
  }

  changeNumOfYears(audit: IAudit, event){
    this.years[audit.id] = event.target.value;
    this.checkExperience();
  }
  save  = () =>{

    const _auditExperiences = [];
    for(let i = 0; i < this.state.models.length; i++){
      if(parseInt(this.years[this.state.models[i].id], 10) >= 0){
        const auditExperience = {year: parseInt(this.years[this.state.models[i].id], 10), audit: this.state.models[i].id};
        _auditExperiences.push(auditExperience);
      }else{
        return;
      }
    }

    const obj = {assessmentId: this.props.match.params.id, auditExperiences: _auditExperiences};

    this.applicantAssessmentService.saveCandidateAssessmentExperience(obj).then(data =>{
      this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/exam');
    });
  }

  checkExperience(){

    let isFilledIn = true;

    for(let i=0;i<this.state.models.length;i++){
      const audit = this.state.models[i];
      if(!this.years[audit.id]){
        isFilledIn = false;
        break;
      }
    }

    this.setState({yearsFilledIn:isFilledIn});
  }

  render() {
    const { isNew, models} = this.state;

    return (
      <div style={{marginTop:20}}>
        <Row className="justify-content-center">
          <Col md="12">
            <h4>
              <b className="pull-left">
                <Translate contentKey="evaluateyouApp.applicantAssessment.experience.title">
                </Translate>
              </b>
            </h4>
          </Col>
        </Row>
        <Card className="main-card">
          <Row className="justify-content-center" style={{marginTop:20}}>
            <Col md="6">
              <p><Translate contentKey="evaluateyouApp.applicantAssessment.experience.note"></Translate></p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <AvForm model={{}}>
                <Table responsive aria-describedby="assessment-heading">
                  <thead>
                  <tr>
                    <th className="hand">
                      <Translate contentKey="evaluateyouApp.applicantAssessment.experience.categories">Categories</Translate>
                    </th>
                    <th className="hand">
                      <Translate contentKey="evaluateyouApp.applicantAssessment.experience.years">Years</Translate>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                    {models
                      ? models.map(audit => (
                        <tr key={audit.id}>
                          <td style={{verticalAlign:'top',lineHeight:'56px'}}>{audit.name}</td>
                          <td>
                            <TextField id="exp-years"
                                       label="Years"
                                       type="number"
                                       required
                                       variant="filled"
                                       name="exp-years"
                                       onChange={ (event) => this.changeNumOfYears(audit, event) }/>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </Table>
              </AvForm>
            </Col>
          </Row>
          <Row className="justify-content-center">
              <Button className="btn-primary" id="save-entity" disabled={!this.state.yearsFilledIn}style={{width:120,height:40}} type="button" onClick={ () => this.save()}>
                <Translate contentKey="evaluateyouApp.applicantAssessment.experience.startTest">Start</Translate>
              </Button>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentExperience);
