import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IAdminConfig} from 'app/shared/model/admin-config.model';

export const ACTION_TYPES = {
  FETCH_ADMINCONFIG_LIST: 'adminConfig/FETCH_ADMINCONFIG_LIST',
  FETCH_ADMINCONFIG: 'adminConfig/FETCH_ADMINCONFIG',
  CREATE_ADMINCONFIG: 'adminConfig/CREATE_ADMINCONFIG',
  UPDATE_ADMINCONFIG: 'adminConfig/UPDATE_ADMINCONFIG',
  DELETE_ADMINCONFIG: 'adminConfig/DELETE_ADMINCONFIG',
  RESET: 'adminConfig/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAdminConfig>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type AdminConfigState = Readonly<typeof initialState>;

// Reducer

export default (state: AdminConfigState = initialState, action): AdminConfigState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ADMINCONFIG_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ADMINCONFIG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ADMINCONFIG):
    case REQUEST(ACTION_TYPES.UPDATE_ADMINCONFIG):
    case REQUEST(ACTION_TYPES.DELETE_ADMINCONFIG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ADMINCONFIG_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ADMINCONFIG):
    case FAILURE(ACTION_TYPES.CREATE_ADMINCONFIG):
    case FAILURE(ACTION_TYPES.UPDATE_ADMINCONFIG):
    case FAILURE(ACTION_TYPES.DELETE_ADMINCONFIG):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ADMINCONFIG_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ADMINCONFIG):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ADMINCONFIG):
    case SUCCESS(ACTION_TYPES.UPDATE_ADMINCONFIG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ADMINCONFIG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/admin-configs';

// Actions

export const getEntities: ICrudGetAllAction<IAdminConfig> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_ADMINCONFIG_LIST,
  payload: axios.get<IAdminConfig>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<IAdminConfig> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ADMINCONFIG,
    payload: axios.get<IAdminConfig>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IAdminConfig> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ADMINCONFIG,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IAdminConfig> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ADMINCONFIG,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAdminConfig> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ADMINCONFIG,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
