import React, {useEffect} from 'react';
import {Button, Card} from '@material-ui/core';
import {Col, Row, Table} from 'reactstrap';
import {Link} from 'react-router-dom';
import {IAssessmentEmail} from "app/shared/model/assessment-email.model";


export const CandidateTableDetails = (props:{assessmentCandidates:IAssessmentEmail[]}) =>{

  const {assessmentCandidates} = props;


  useEffect(()=>{
    console.log(assessmentCandidates);
  },[])



  return (
    <div>
      <Row>
        <Col md="9">
          <h4 className="pull-left" id="title-style">
            Recent Candidates
          </h4>
          <Card className="card-design">

            <Row>
              <Col md="12">
                <div className="table-responsive">
                  {assessmentCandidates && assessmentCandidates.length > 0 ? (
                    <Table responsive aria-describedby="training-heading">
                      <thead>
                      <tr>
                         <th>Assessment</th>
                         <th>Email</th>
                         <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      {assessmentCandidates.map((a, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{a.assessment.title}</td>
                          <td>{a.email}</td>
                          <td>
                            <Button>
                              <Link to={`/assessment/${a.assessment.id}/l`}>
                                VIEW
                              </Link>
                            </Button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning">
                      No Candidates found
                    </div>
                  )}
                </div>
              </Col>
            </Row>

          </Card>
        </Col>

      </Row>
    </div>
  );

}
