import './footer.scss';

import React from 'react';

const Footer = () => (
  <div className="footer py-3" id="contact" style={{height:'auto'}}>
    <p className="footer-text my-0">contact@evaluateyou.com</p>
    <p className="footer-text my-0">Plaza Damas, C-2-11, Jalan Sri Hartamas 1, Taman Sri Hartamas, 50480 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur, Malaysia</p>
  </div>
);

export default Footer;
