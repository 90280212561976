import './applicant-assessment.css';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {AvField, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IAssessment} from 'app/shared/model/assessment.model';
import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  setUnselectedQuestions,
  updateEntity,
} from './applicant-assessment.reducer';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {CONSTANTS, MAX_SCORE} from 'app/shared/util/global.constants';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {displayHtml, displayImage, unescapeHtmlAndNewLine} from "app/shared/util/common-utils";
import {IAuditQuestion} from 'app/shared/model/audit-question.model';

export interface IApplicantAssessmentExamProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface IApplicantAssessmentExamState {
  isNew: boolean;
  assessmentId: string;
  totalTime: number;
  spentTime: number;
  spentTimeFormatted: string;
  leftTime: number;
  leftTimeFormatted: string;
  currentIdx: number; // current question index
  questions: IAuditQuestion[];
  currentQuestion: any;
  allowNext: boolean,
  assessment: IAssessment;
  showFullNameForm: boolean;
  showMissedQuestionsForm: boolean;
  showConfirmSubmitForm: boolean;
  fullName: string;
  unselectedQuestions: any;
  models: any;
  isCompleted: boolean;
}

export class ApplicantAssessmentExam extends React.Component<IApplicantAssessmentExamProps, IApplicantAssessmentExamState> {
  applicantAssessmentService: ApplicantAssessmentService;
  ONE_SECOND = 1000;
  ONE_MINUTE = 60 * this.ONE_SECOND;
  examTimer: any;
  totalTimeFormatted: any;

  constructor(props) {
    super(props);
    this.state = {
      assessmentId: window.location.pathname.replace('/applicant-assessment/', ""),
      isNew: !this.props.match.params || !this.props.match.params.id,
      totalTime: 0,
      spentTime: 0,
      spentTimeFormatted: "",
      leftTime: 0,
      leftTimeFormatted: "",
      currentIdx: 0,
      questions: [],
      currentQuestion: {},
      allowNext: false,
      assessment: {},
      showFullNameForm: false,
      showMissedQuestionsForm: false,
      showConfirmSubmitForm: false,
      fullName: localStorage.getItem(CONSTANTS.FULL_NAME),
      unselectedQuestions: [],
      models: [],
      isCompleted: false,
    };
  }

  countdown() {
    let self = null;
    self = this;

    self.examTimer = setTimeout(function () {
      const {showMissedQuestionsForm} = self.state;
      if (showMissedQuestionsForm) {
        self.countdown();
        return;
      }

      const _spentTime = self.state.spentTime + self.ONE_SECOND;
      const _spentTimeFormatted = Math.floor(_spentTime / self.ONE_MINUTE) + "m: " + ((_spentTime % self.ONE_MINUTE) / self.ONE_SECOND) + "s";

      const _leftTime = self.state.totalTime * self.ONE_MINUTE - _spentTime;
      const _leftTimeFormatted = Math.floor(_leftTime / self.ONE_MINUTE) + "m: " + ((_leftTime % self.ONE_MINUTE) / self.ONE_SECOND) + "s";

      self.setState({
        spentTime: _spentTime,
        spentTimeFormatted: _spentTimeFormatted,
        leftTime: _leftTime,
        leftTimeFormatted: _leftTimeFormatted
      });
      if (_spentTime < self.state.totalTime * self.ONE_MINUTE) {
        self.countdown();
        self.storeExam();
      } else {
        self.outOfTime();
      }

    }, self.ONE_SECOND);
  }

  outOfTime = () => {
    this.submit();
  }
  resetTimer = () => {
    clearTimeout(this.examTimer);
    this.examTimer = null;
  }

  repopulateExamFromStorage(examObj: any): void {
    const exam = JSON.parse(examObj);

    const _questions = exam.questions;
    const _unanswered = exam.unselectedQuestions;
    const _currentIdx = exam.currentIdx;
    const _currentQuestion = _questions[_currentIdx];
    const _spentTime = exam.time;
    if (_unanswered && _unanswered.length > 0)
      _unanswered.forEach(q => this.state.unselectedQuestions.push(q));
    this.setState({
      questions: _questions,
      currentQuestion: _currentQuestion,
      currentIdx: _currentIdx,
      spentTime: _spentTime
    });

    this.calculateTimeToDoExam(_questions);
    this.countdown();
    this.storeExam();
  }

  retrieveExamFromRemote(): void {
    this.applicantAssessmentService.generateExam(this.props.match.params.id).then(data => {

      const questions: IAuditQuestion[] = data;

      const currentQuestion = questions[0];
      this.setState({questions: questions, currentQuestion: currentQuestion});

      this.calculateTimeToDoExam(questions);
      this.countdown();
      console.log(`Storing exam - exam`);
      this.storeExam();

    });
  }

  componentDidMount() {
    this.applicantAssessmentService = new ApplicantAssessmentService();
    const examObj = localStorage.getItem(CONSTANTS.EXAM_DATA + this.props.match.params.id);
    if (examObj && JSON.parse(examObj).questions.length !== 0) {
      this.repopulateExamFromStorage(examObj);
    } else {
      this.retrieveExamFromRemote();
    }

    this.applicantAssessmentService.getApplicantAssessmentEmailDTOs().then(data => {
      if (data === null) {
        data = [];
      }
      this.setState({models: data}, () => {
        this.state.models.forEach(currentAssessment => {

          if (currentAssessment.assessmentId === this.state.assessmentId.replace('/exam', "")) {
            if (currentAssessment.score !== null && !currentAssessment.allowPublic){ // allow users to resit as many times for public assessments
              this.setState({isCompleted: true});
            }
          }
        });
      });

    });

  }


  storeExam = () => {
    const obj = {
      id: this.props.match.params.id,
      time: this.state.spentTime,
      currentIdx: this.state.currentIdx,
      questions: this.state.questions,
      unselectedQuestions: this.state.unselectedQuestions,
    };
    localStorage.setItem(CONSTANTS.EXAM_DATA + obj.id, JSON.stringify(obj));
  }
  removeExam = () => {
    localStorage.removeItem(CONSTANTS.EXAM_DATA + this.props.match.params.id);
  }

  calculateTimeToDoExam = (questions) => {
    let minutes = 0, seconds = 0;

    for (let i = 0; i < questions.length; i++) {
      const time = questions[i].time.split(":");
      minutes += parseInt(time[0], 10);
      seconds += parseInt(time[1], 10);
    }
    const totalTime = this.ONE_MINUTE * minutes + this.ONE_SECOND * seconds;
    let examTime = Math.floor(totalTime / this.ONE_MINUTE);
    const mod = examTime % 5;
    if (mod !== 0) {
      examTime += (5 - mod);
    }
    this.totalTimeFormatted = Math.floor(examTime) + "m";
    console.log("examTime: " + examTime);

    this.setState({totalTime: examTime});

  }
  handleNext = (event) => {
    this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/experience');
  }
  gotoResult = () => {
    this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/exam-result');
  }

  submit = () => {
    this.resetTimer();

    const {totalTime, spentTime} = this.state;
    console.log(totalTime);

    this.props.setUnselectedQuestions(null);
    this.applicantAssessmentService.saveExam(
      {
        id: this.props.match.params.id,
        questions: this.state.questions,
        email: this.props.account.login,
        fullName: this.state.fullName,
        totalTime: Math.floor(totalTime * this.ONE_MINUTE / this.ONE_SECOND),
        spentTime: Math.floor(spentTime / this.ONE_SECOND)
      }).then(data => {
        console.log(`removing exam ${this.props.match.params.id}`);
      localStorage.removeItem(CONSTANTS.EXAM_DATA + this.props.match.params.id);
      this.gotoResult();
    });

  }

  doSave() {
    const unselected = [];

    if (unselected.length !== 0) {
      this.setState({showMissedQuestionsForm: true, unselectedQuestions: unselected, showConfirmSubmitForm: false});
    } else {
      this.setState({showMissedQuestionsForm: false, unselectedQuestions: [], showConfirmSubmitForm: true});
    }
  }

  gotoQuestion = (index) => {
    console.log(this.state.questions[index]);
    if (this.state.currentIdx < index)
      for (let i = this.state.currentIdx; i < index; i++) {
        if (this.state.questions[this.state.currentIdx].candidateAnswers.length === 0)
          this.skippedQuestion(i);
      }
    else
      for (let i = this.state.currentIdx; i > index; i--) {
        if (this.state.questions[this.state.currentIdx].candidateAnswers.length === 0)
          this.skippedQuestion(i);
      }
    this.setState({currentIdx: index, currentQuestion: this.state.questions[index]});
  }

  previousQuestion = () => {
    if (this.state.currentIdx === 0) return;
    if (this.state.questions[this.state.currentIdx].candidateAnswers.length === 0) {
      this.skippedQuestion(this.state.currentIdx);
    }
    const _currentIdx = this.state.currentIdx - 1;
    const _currentQuestion = this.state.questions[_currentIdx];
    this.setState({currentIdx: _currentIdx, currentQuestion: _currentQuestion});
  }

  skippedQuestion = (question: number) => {
    let isExist = false;

    this.state.unselectedQuestions.forEach(q => {
      if (q === question) {
        isExist = true;
      }
    })

    if (isExist === false) {
      this.state.unselectedQuestions.push(question);
      this.props.setUnselectedQuestions(this.state.unselectedQuestions);
    }
  }

  nextQuestion = () => {
    if (this.state.currentIdx === MAX_SCORE - 1) return;
    if (this.state.questions[this.state.currentIdx].candidateAnswers.length === 0) {
      this.skippedQuestion(this.state.currentIdx);
    }
    const _currentIdx = this.state.currentIdx + 1;
    const _currentQuestion = this.state.questions[_currentIdx];
    this.setState({currentIdx: _currentIdx, currentQuestion: _currentQuestion});
  }

  radioChangeValue(questionIndex, questionOptionId, event) {
    const selectedQuestion = this.state.questions[questionIndex];
    let counter = 0;
    if (event.target.checked) {
      selectedQuestion.candidateAnswers.pop();
      selectedQuestion.candidateAnswers.push(questionOptionId);
      if (this.state.unselectedQuestions.length > 0) {
        this.state.unselectedQuestions.forEach(q => {
          if (q === questionIndex) {
            this.state.unselectedQuestions.splice(counter, 1);
            this.props.setUnselectedQuestions(this.state.unselectedQuestions);
          } else counter++;
        })
      }
    }
  }

  checkboxChangeValue(questionIndex, questionOptionId, checked) {
    const {questions} = this.state;
    let counter = 0;
    const selectedQuestion = questions[questionIndex];
    if (checked) {
      selectedQuestion.candidateAnswers.push(questionOptionId);
      if (this.state.unselectedQuestions.length > 0) {
        this.state.unselectedQuestions.forEach(q => {
          if (q === questionIndex) {
            this.state.unselectedQuestions.splice(counter, 1);
            this.props.setUnselectedQuestions(this.state.unselectedQuestions);
          } else counter++;
        })
      }
    } else {
      const updatedAnswers = selectedQuestion.candidateAnswers.filter(existingOption => existingOption !== questionOptionId);
      selectedQuestion.candidateAnswers = updatedAnswers;
    }
    questions[questionIndex] = selectedQuestion;

    this.setState({questions: questions});

  }

  hasChoose(questionIndex, optionId) {

    const selectedQuestion = this.state.questions[questionIndex];
    if (selectedQuestion) {
      return selectedQuestion.candidateAnswers.includes(optionId);
    } else {
      return false;
    }

  }

  hasAnswer(index) {
    // TODO retrieve from questions
  }


  setModelValue = (field, event) => {
    if (field === "fullName") {
      this.setState({fullName: event.target.value});
    }
  }

  hideFullNameForm = () => {
    this.setState({showFullNameForm: false});
  }

  renderFullNameForm() {
    const {showFullNameForm} = this.state;
    return (<Modal isOpen={showFullNameForm} toggle={() => this.hideFullNameForm()} backdrop={true}>
      <ModalHeader toggle={() => this.hideFullNameForm()}>Full Name</ModalHeader>
      <ModalBody>
        <AvForm model={{}}>
          <AvGroup>
            <Label id="nameLabel" for="full-name">
              <Translate contentKey="evaluateyouApp.applicantAssessment.exam.fullName">Name</Translate>
            </Label>
            <AvField
              id="full-name"
              type="text"
              name="name"
              validate={{
                required: {value: true, errorMessage: translate('entity.validation.required')},
                maxLength: {value: 500, errorMessage: translate('entity.validation.maxlength', {max: 500})}
              }}
              onChange={(event) => this.setModelValue("fullName", event)}
            />
          </AvGroup>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideFullNameForm()}> <Translate
          contentKey="entity.action.ok">Name</Translate></Button>{' '}
        <Button color="secondary" onClick={() => this.hideFullNameForm()}><Translate
          contentKey="entity.action.cancel">Name</Translate></Button>
      </ModalFooter>
    </Modal>);
  }

  hideMissedQuestionsDialog = () => {
    this.setState({showMissedQuestionsForm: false});
  }

  renderMissedQuestionsDialog() {
    const {showMissedQuestionsForm, unselectedQuestions, leftTimeFormatted} = this.state;
    return (<Modal isOpen={showMissedQuestionsForm} toggle={() => this.hideMissedQuestionsDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideMissedQuestionsDialog()}>Submit assessment confirmation</ModalHeader>
      <ModalBody>
        <p>You have {unselectedQuestions.length} unfinished questions and {leftTimeFormatted} left</p>
        <p>Do you want to proceed with the submission?</p>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-primary2" onClick={() => this.submit()}>
          <FontAwesomeIcon icon="upload"/>{'  '}
          <Translate contentKey="entity.action.submitAssessment"></Translate>
        </Button>{' '}
        <Button color="primary" onClick={() => this.hideMissedQuestionsDialog()}>
          <FontAwesomeIcon icon="ban"/>{'  '}
          <Translate contentKey="entity.action.cancelAndTest"></Translate>nd
        </Button>
      </ModalFooter>
    </Modal>);
  }

  hideConfirmSubmitDialog = () => {
    this.setState({showConfirmSubmitForm: false});
  }

  renderConfirmSubmitDialog() {
    const {showConfirmSubmitForm} = this.state;

    return (<Modal isOpen={showConfirmSubmitForm} toggle={() => this.hideConfirmSubmitDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideConfirmSubmitDialog()}>Confirm</ModalHeader>
      <ModalBody>
        <p>Are you ready to submit?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => this.hideConfirmSubmitDialog()}>
          <FontAwesomeIcon icon="ban"/>{'  '}
          <Translate contentKey="entity.action.cancelAndTest"></Translate>
        </Button>{' '}

        <Button color="primary" onClick={() => this.submit()}>
          <FontAwesomeIcon icon="upload"/>{'  '}
          <Translate contentKey="entity.action.submitAssessment"></Translate>
        </Button>

      </ModalFooter>
    </Modal>);
  }

  renderPaging = () => {
    const {currentIdx} = this.state;
    let start = 0;
    let maxButtonsToDisplay = 7;
    if (currentIdx > 4) {
      start = currentIdx - 3;
    }

    const totalQuestions = this.state.questions.length;

    // if the number of questions is too small
    if (totalQuestions < maxButtonsToDisplay) {
      maxButtonsToDisplay = totalQuestions;
    }

    if (currentIdx > totalQuestions - maxButtonsToDisplay) {
      start = totalQuestions - maxButtonsToDisplay;
    }
    const action = [];
    action.push(<span key={Math.random()} className="paging-box"
                      onClick={(event) => this.previousQuestion()}><FontAwesomeIcon icon="chevron-left"/></span>);
    let lastRenderItem, i = 0;
    for (lastRenderItem = start; i < maxButtonsToDisplay; i++, lastRenderItem++) {
      const index = lastRenderItem;
      if (lastRenderItem === currentIdx) {
        action.push(<span key={Math.random()} className="paging-box paging-box-active"
                          onClick={(event) => this.gotoQuestion(index)}>{lastRenderItem + 1}</span>);
      } else {
        action.push(<span key={Math.random()} className="paging-box"
                          onClick={(event) => this.gotoQuestion(index)}>{lastRenderItem + 1}</span>);
      }

    }
    if (lastRenderItem <= totalQuestions - 3) {
      action.push(<span key={Math.random()} className="paging-box-etc">...</span>);
      action.push(<span key={Math.random()} className="paging-box"
                        onClick={(event) => this.gotoQuestion(totalQuestions - 1)}>{totalQuestions}</span>);
    }
    action.push(<span key={Math.random()} className="paging-box" onClick={(event) => {
      if (currentIdx !== totalQuestions - 1)
        this.nextQuestion()
    }}><FontAwesomeIcon icon="chevron-right"/></span>);
    return <div className="inline-center">{action}</div>;
  }

  missedQuestion = () => {
    const unattemptedList = this.state.unselectedQuestions;

    if (unattemptedList.length > 0) {
      return (
        <div className="missed-question-list">
          <p>Skipped Questions :<br/>
            {unattemptedList.sort().map(unattempted => {
              return (
                <Button key={unattempted} className="missed-question"
                        onClick={(event) => this.gotoQuestion(unattempted)}>{unattempted + 1}</Button>
              )
            })}
          </p>
        </div>
      )
    } else return null;
  }

  render() {
    const {questions, currentIdx, currentQuestion, leftTimeFormatted, isCompleted} = this.state;
    if (isCompleted===false) {
      return (
        <div className="question-selection" onContextMenu={(e) => e.preventDefault()}>

          <Row>
            <Col md="6">
              <div className="pull-left">
                <div style={{textAlign: 'left'}}>
                  <b>
                    <Translate contentKey="evaluateyouApp.applicantAssessment.exam.title"></Translate>
                  </b>
                </div>
                <div className="paddingLeft"><Translate
                  contentKey="evaluateyouApp.applicantAssessment.exam.leftTime"></Translate>: <span>{leftTimeFormatted}</span>
                </div>
              </div>
            </Col>
            <Col md="6" style={{textAlign: 'right'}}>
              <Button color="primary" className="pull-right" id="save-entity" type="button"
                      onClick={(event) => this.doSave()}>
                <FontAwesomeIcon icon="upload"/>{'  '}
                <Translate contentKey="entity.action.submitAssessmentNow"></Translate>
              </Button>
            </Col>
          </Row>

          <Card className="main-card">
            {this.renderFullNameForm()}
            {this.renderMissedQuestionsDialog()}
            {this.renderConfirmSubmitDialog()}
            <Row className="justify-content-center">
              <Col md="12">
                <div className="noselect">
                  <div style={{marginBottom: "20px"}}>
                    <div className="tag">
                      {currentQuestion.audit ? currentQuestion.audit.name : ''}
                    </div>
                    <div className="audit-question">
                      {currentIdx + 1}. <span dangerouslySetInnerHTML={displayHtml(currentQuestion.title)}></span>
                    </div>

                    {currentQuestion.type === 3 ?
                      (<span><Translate
                        contentKey="evaluateyouApp.applicantAssessment.exam.chooseAllThatApply"></Translate></span>) :
                      (<span></span>)
                    }

                    {currentQuestion.code ? (
                      <SyntaxHighlighter style={docco} showLineNumbers={true}>
                        {currentQuestion.code}
                      </SyntaxHighlighter>
                    ) : (<span></span>)
                    }

                    {currentQuestion.questionFiles
                      ? currentQuestion.questionFiles.map((file, index) => (
                        <div key={Math.random()} style={{marginBottom: "5px"}}>
                          <img style={{padding: "10px"}} src={displayImage(file.data)}/>
                        </div>
                      ))
                      : null}

                    {/* Question Options */}
                    {currentQuestion.questionOptions
                      ? currentQuestion.questionOptions.map((auditQuestionOption, index) => (
                        <div key={Math.random()} style={{marginTop: "15px", marginBottom: "15px"}}>
                          {currentQuestion.type === 3 ?
                            (<div>
                              <div className="pretty p-default p-curve p-thick">

                                <input type="checkbox" defaultChecked={this.hasChoose(currentIdx, auditQuestionOption.id)}
                                       onChange={(event) => this.checkboxChangeValue(currentIdx, auditQuestionOption.id, event.target.checked)}/>

                                <div className="state p-primary-o">
                                  {!auditQuestionOption.highlight ? <label style={{
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                    display: "inline-flex",
                                    paddingLeft: "20px"
                                  }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label> : ""}
                                  <label>&nbsp;</label>
                                </div>

                              </div>

                              {auditQuestionOption.highlight ? (
                                <SyntaxHighlighter style={docco} showLineNumbers={true}>
                                  {unescapeHtmlAndNewLine(auditQuestionOption.title)}
                                </SyntaxHighlighter>
                              ) : (<span></span>)
                              }
                            </div>) : (<div>
                              <div className="pretty p-default p-round p-thick">
                                <input type="radio" name={"questionOption" + currentQuestion.id}
                                       defaultChecked={this.hasChoose(currentIdx, auditQuestionOption.id)}
                                       onChange={(event) => this.radioChangeValue(currentIdx, auditQuestionOption.id, event)}/>
                                <div className="state p-primary-o">
                                  {!auditQuestionOption.highlight ? <label style={{
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                    display: "inline-flex",
                                    paddingLeft: "20px"
                                  }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label> : ""}
                                  <label>&nbsp;</label>
                                </div>
                              </div>
                              {auditQuestionOption.highlight ? (
                                <SyntaxHighlighter style={docco} showLineNumbers={true}>
                                  {unescapeHtmlAndNewLine(auditQuestionOption.title)}
                                </SyntaxHighlighter>
                              ) : (<span></span>)
                              }
                            </div>)
                          }
                        </div>

                      ))
                      : null}


                  </div>
                </div>

                {this.missedQuestion()}


                <div className="tab-content">
                  {currentIdx !== 0 ? (<Button className="pull-left" color="secondary" id="save-entity" type="button"
                                               onClick={(event) => this.previousQuestion()}>
                    <FontAwesomeIcon icon="chevron-left"/>{'  '} Previous Question
                  </Button>) : (<span></span>)}
                  {this.renderPaging()}
                  {currentIdx !== questions.length - 1 ? (
                    <Button className="pull-right" color="secondary" id="save-entity" type="button"
                            onClick={(event) => this.nextQuestion()}>
                      Next Question {'  '}<FontAwesomeIcon icon="chevron-right"/>
                    </Button>) : (<span></span>)}
                </div>

              </Col>
            </Row>


          </Card>
        </div>
      );
    }
    else {
      return (
        <div>
          <Row className="pull-left">
            <Col md="12">
              <h2>
                <b>
                  <Translate contentKey="evaluateyouApp.applicantAssessment.exam.title">
                  </Translate>
                </b>
              </h2>
            </Col>
          </Row>
          <Card className="main-card">
            <Row className="justify-content-center">
              <Col md="8">
                <p> This test has been completed by the candidate.</p>
                <p> Thank you and have a nice day!</p>
              </Col>
            </Row>
          </Card>
        </div>
      )
    }
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  unselectedQuestions: storeState.applicantAssessment.unselectedQuestions,
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
  setUnselectedQuestions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentExam);
