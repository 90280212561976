import React from 'react';
import './candidate-training-dashboard.scss';
import {Col, Label, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {InputAdornment, OutlinedInput} from "@material-ui/core"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {RouteComponentProps} from 'react-router-dom';
import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import {
  getEntities,
  getRecommendedTrainings,
  searchEntities
} from 'app/entities/training/training.reducer';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {
  TrainingCardDisplay
} from 'app/modules/candidate-training-dashboard/components/training-card-display';
import {ITraining} from 'app/shared/model/training.model';


export interface ITrainingListProps extends StateProps, DispatchProps, RouteComponentProps <{ url: string }> {
}

export interface ITrainingListState extends IPaginationBaseState {
    searchField: string
}

export class TrainingList extends React.Component<ITrainingListProps, ITrainingListState> {
    state: ITrainingListState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        searchField: ""
    };


    componentDidMount() {
        this.getEntities();
        this.props.getRecommendedTrainings();
        this.onHandleSearchChange = this.onHandleSearchChange.bind(this)
        this._handleSearchKeyDown = this._handleSearchKeyDown.bind(this)
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop
            },
            () => this.sortEntities()
        );
    };

    filterRecommendedTrainings(trainings: readonly ITraining[]) {
        if (this.props.recommendedTrainings && this.props.recommendedTrainings.length > 0) {
            return trainings.filter(t => {
                if (this.props.recommendedTrainings.find(r => r.id !== t.id)) {
                    return t;
                }
            });
        } else {
            return trainings;
        }
    }

    getEntities = () => {
        const {activePage, itemsPerPage, sort, order} = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({activePage}, () => this.sortEntities());

    onHandleSearchChange(e) {
        this.setState({
            ...this.state,
            searchField: e.target.value
        })
    }

    _handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (this.state.searchField.length === 0) {
                this.getEntities()
            } else {
                this.props.searchEntities(this.state.searchField)
            }
        }
    }

    render() {
        const {searchField} = this.state
        const {trainingApplyList, match, totalItems} = this.props;
        return (
            <div style={{padding: 50}}>
                <Row>
                    <Row className="w-100">
                        <Col sm={12} style={{height: 80}}>
                            <h4 className="pull-left" id="title-style">
                                Trainings
                            </h4>
                        </Col>
                    </Row>
                </Row>

                {
                    this.props.recommendedTrainings && this.props.recommendedTrainings.length > 0 &&
                  <Row className="w-100">
                    <Row className="w-100">
                      <Label className="ml-3 my-3">
                        <b>Recommended Trainings</b>
                      </Label>
                    </Row>
                    <Row className="w-100">
                      <TrainingCardDisplay trainingApplyList={this.props.recommendedTrainings}/>
                    </Row>
                  </Row>
                }

                {trainingApplyList && trainingApplyList.length > 0 && this.filterRecommendedTrainings(trainingApplyList).length > 0 ? (
                    <Row className="mt-5 w-100">
                        <Row className="w-100">
                            <Label className="ml-3 my-3">
                                <b>Other Trainings</b>
                            </Label>
                            <OutlinedInput
                                className="pull-left py-0 ml-3"
                                placeholder="Search by Name"
                                id="input-with-icon-adornment"
                                value={searchField}
                                onChange={this.onHandleSearchChange}
                                onKeyDown={this._handleSearchKeyDown}
                                endAdornment={
                                    <InputAdornment position="start" className="py-0">
                                        <FontAwesomeIcon icon={"search"}/>
                                    </InputAdornment>
                                }

                                style={{height: 40, minWidth: 600, backgroundColor: '#ffffff'}}
                            />
                        </Row>
                        <Row className="w-100">
                            <TrainingCardDisplay
                                trainingApplyList={this.filterRecommendedTrainings(trainingApplyList)}/>
                        </Row>
                    </Row>
                ) : (
                    <Row className="justify-content-center">
                        <Col xs={6} className="alert alert-warning">
                            <Translate contentKey="evaluateyouApp.training.home.notFound">No
                                Trainings found</Translate>
                        </Col>
                    </Row>
                )}


                {
                    this.props.trainingApplyList && this.props.trainingApplyList.length > 0 &&
                  <div>
                    <Row className="justify-content-center">
                      <JhiItemCount page={this.state.activePage} total={totalItems}
                                    itemsPerPage={this.state.itemsPerPage} i18nEnabled/>
                    </Row>
                    <Row className="justify-content-center">
                      <JhiPagination
                        activePage={this.state.activePage}
                        onSelect={this.handlePagination}
                        maxButtons={5}
                        itemsPerPage={this.state.itemsPerPage}
                        totalItems={this.props.totalItems}
                      />
                    </Row>
                  </div>
                }

            </div>
        );
    }
}

const mapStateToProps = ({training}: IRootState) => ({
    trainingApplyList: training.entities,
    recommendedTrainings: training.recommendedTrainings,
    totalItems: training.totalItems,
});

const mapDispatchToProps = {getSession, getEntities, searchEntities, getRecommendedTrainings};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingList);
