import './assessment.scss';

import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getAssessments} from 'app/entities/assessment/assessment.reducer';
import {
  getEntities as getAssessmentEmails
} from 'app/entities/assessment-email/assessment-email.reducer';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';

export interface IApplicantAssessmentInformProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ChooseAssessment extends React.Component<IApplicantAssessmentInformProps, any> {
  applicantAssessmentService: ApplicantAssessmentService;

  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentDidMount() {
  }

  handleNext = (event) =>{
    this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/experience');
  }
  addNewAssessment = (event) =>{
    this.props.history.push('/assessment/new');
  }
  addNewCustomAssessment = (event) =>{
    this.props.history.push('/assessment/new-custom-questions');
  }

  selectOldAssessment = (event) =>{
    this.props.history.push('/assessment/new-existing-questions');
  }

  render() {
    return (
        <Row className="w-100 mx-5 justify-content-center">
          <Col md={11}>
              <Row className="w-100 justify-content-center">


                <Col md="4" onClick={(event) => this.addNewAssessment(event)}>
                  <Row className="w-100 guide-me"  style={{height:'100%'}}>
                    <Col md="8">
                      <h4><b><Translate contentKey="evaluateyouApp.assessment.chooseWay.guideMe"></Translate></b></h4>
                      <span>
                        <Translate contentKey="evaluateyouApp.assessment.chooseWay.firstWay">
                        </Translate>
                      </span>
                    </Col>
                    <Col md="4">
                      <img src="content/images/guide_me.png"/>
                    </Col>
                  </Row>
                </Col>

                <Col md="4" onClick={(event) => this.addNewCustomAssessment(event)}>
                  <Row className="w-100 let-me-pick"  style={{height:'100%'}}>
                    <Col md="8">
                      <h4><b><Translate contentKey="evaluateyouApp.assessment.chooseWay.letMePick">Next</Translate></b></h4>
                      <span>
                    <Translate contentKey="evaluateyouApp.assessment.chooseWay.secondWay">
                    </Translate>
                  </span>
                    </Col>
                    <Col md="4">
                      <img src="content/images/let_me_pick.png"/>
                    </Col>
                  </Row>
                </Col>

                <Col md="4" onClick={(event) => this.selectOldAssessment(event)}>
                  <Row className="w-100 select-from-existing" style={{height:'auto'}}>
                    <Col md="8">
                      <h4><b><Translate contentKey="evaluateyouApp.assessment.chooseWay.selectFromExisting"></Translate></b></h4>
                      <span>
                        <Translate contentKey="evaluateyouApp.assessment.chooseWay.thirdWay">
                        </Translate>
                      </span>
                    </Col>
                    <Col md="4">
                      <img src="content/images/select_from_existing.png"/>
                    </Col>
                  </Row>
                </Col>
              </Row>
          </Col>

        </Row>

    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
});

const mapDispatchToProps = {
  getAssessments,
  getAssessmentEmails
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseAssessment);
